import { Component, ViewChild } from "@angular/core";
import {
  DataTableComponentBase,
  Filter,
  TableSchema,
  TableService,
  TableTypes,
} from "@kortobaa-front/k-ng-datatable";
import { AuthService } from "src/app/core/auth.service";
import { DataService } from "src/app/shared/services/data.service";
import { DataTableComponent } from "@kortobaa-front/k-ng-datatable";
import * as moment from "moment-timezone";
import { PromoCodesService } from "../../services/promo-codea.service";
import { DeleteConfirmDialog } from "src/app/shared/delete-confirm-dialog/delete-confirm-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { PromoCode } from "src/app/shared/models/interfaces";
import { AddPromoCodeDialogComponent } from "../../dialogs/add-promo-code-dialog/add-promo-code-dialog.component";

@Component({
  selector: "app-active-promo-code",
  templateUrl: "./active-promo-code.component.html",
  styleUrls: ["./active-promo-code.component.scss"],
})
export class ActivePromoCodeComponent extends DataTableComponentBase {
  moduleName = "coupons";
  url = "coupons";
  dataSource;
  isDataLoading = false;
  displayedColumns: TableSchema[] = [
    {
      title: "code-name",
      id: "title",
      type: TableTypes.text,
      editable: false,
    },
    {
      title: "discount-percentage",
      id: "discount",
      type: TableTypes.number,
      editable: false,
    },
    {
      title: "expire-date",
      id: "expired",
      type: TableTypes.text,
      editable: false,
    },
  ];

  @ViewChild(DataTableComponent)
  private _dataTableComponent: DataTableComponent;

  constructor(
    public authService: AuthService,
    private _tableService: TableService,
    private _dataService: DataService,
    private _promoCodesService: PromoCodesService,
    private _dialog: MatDialog,
    private router: Router,
  ) {
    super();
    this._tableService.tableUrl = "admin/coupons";
  }
  filters: Filter = {
    where: {
      and: [
        { expired_at: { gt: new Date(Date.now()).toISOString() } },
      ],
    },
  };
  async getData(filters?: Filter) {
    filters["where"] = this.filters.where;
    this.isDataLoading = true;
    try {
      let data = await this._tableService.getData<any>(filters).toPromise();
      this.dataSource = data.map((elem) => {
        elem["expired"] = moment(new Date(elem["expired_at"])).format("LL");
        elem["discount"] = `${elem["discountPercentage"]} % `;
        return elem;
      });
    } catch (err) {
      // console.error(err);
    }
    this.isDataLoading = false;
  }

  getDataForAction(data) {
    this._tableService.SaveItem(data).subscribe(
      (row: any) => {
        this._tableService.redirect(this.url);
      },
      (error) => {
        this._dataService.notification(error.error.error.message);
      }
    );
  }

  isIsoDate(arg0: any) {
    throw new Error("Method not implemented.");
  }

  // show consultant data by redirect
  async handleActionOnPromoCode(data: any) {
    if (data.emit == 'delete') {
      this.deletePromoCode(data.data.id)
    }
    if (data.emit == 'edit') {
      this.editPromoCode(data.data)
    }
  }

  // handle delete promo code
  deletePromoCode(id: number) {
    this._dialog
      .open(DeleteConfirmDialog, {
        direction: "rtl",
        panelClass: "",
        autoFocus: false,
        data: {
          message: "هل انت متأكد من انك تريد حذف كود الخصم ؟",
          icon: "delete_forever",
        },
      })
      .afterClosed()
      .subscribe((accept: boolean) => {
        if (accept) {
          this._promoCodesService
            .deletePromoCode(id)
            .subscribe((data) => {
              this.router
                .navigateByUrl('/', { skipLocationChange: true })
                .then(() => {
                  this.router.navigate(['/services/promo-code']);
                })
                .then(() => {
                  this._dataService.notification("تم حذف الكود بنجاح", 'success-snackbar');
                })
            });
        }
      });
  }

  // handle edit promo code
  editPromoCode(promoCodeData: PromoCode) {
    this._dialog
      .open(AddPromoCodeDialogComponent, {
        direction: "rtl",
        panelClass: "save-case-dialog",
        data: {
          promoCodeData: promoCodeData
        },
      })
  }
}
