import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/core/auth.service";
import { MenuService } from "src/app/ui/layout/menu/menu.service";
import { DataService } from "src/app/shared/services/data.service";
import { MediaObserver } from "@angular/flex-layout";
import { Subscription } from "rxjs";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  errorMessage: string;
  hide = true;

  mediaSub: Subscription;
  deviceXS: boolean;

  userData: any = { email: "", password: "" };

  constructor(
    private router: Router,
    private authService: AuthService,
    private menuService: MenuService,
    private dataService: DataService,
    public mediaObserver: MediaObserver
  ) {}

  ngOnInit() {
    //
  }

  ngOnDestroy() {
    //
  }

  async login() {
    let _this = this;
    this.authService.LogIn(this.userData).subscribe(
      async (res: any) => {
        localStorage.setItem("token", res.token);
        localStorage.setItem("User", JSON.stringify(res));
        this.dataService.currentUser = JSON.parse(JSON.stringify(res));
        this.menuService.updateMenuData();
        _this.router.navigate(["/home"]);
      },
      (err) => {
        this.errorMessage = err.error.error.message;
        if (this.errorMessage === "Invalid credentials")
          this.errorMessage = "بيانات الدخول غير صحيحة";
        else this.errorMessage = "حدث خطأ ما";
      }
    );
  }

  clearErrorMessage() {
    if (this.errorMessage) this.errorMessage = "";
  }
}
