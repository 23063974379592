import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { AddServiceDialogComponent } from "../../dialogs/add-service-dialog/add-service-dialog.component";
import { ServicesService } from "../../services/services.service";
import { ServiceList } from "src/app/shared/models/interfaces";
import { DataService } from "src/app/shared/services/data.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-service-type-page",
  templateUrl: "./service-type-page.component.html",
  styleUrls: ["./service-type-page.component.scss"],
})
export class ServiceTypePageComponent implements OnInit {
  serviceList: ServiceList[] = [];
  isLoading = false;
  serviceListSub$!: Subscription;
  dialogSub$!: Subscription;

  constructor(
    public translate: TranslateService,
    private _dialog: MatDialog,
    private _servicesService: ServicesService,
    public dataService: DataService
  ) { }

  ngOnInit(): void {
    this.getServiceList();
  }

  // get service
  getServiceList() {
    this.isLoading = true;
    this.serviceListSub$ = this._servicesService.getServiceList().subscribe((res) => {
      this.serviceList = res;
      this.isLoading = false;
    });
  }

  // open add service
  async openAddServiceDialog() {
    this.dialogSub$ = this._dialog
      .open(AddServiceDialogComponent, {
        direction: "rtl",
        panelClass: "save-case-dialog",
        data: {},
      })
      .afterClosed()
      .subscribe((newService: any) => {
        if (newService) {
          this.dataService.notification("تم إضافة الخدمة بنجاح", 'success-snackbar');
          this.getServiceList();
        }
      });
  }

  // handle update service list when delete service
  updateService(response: boolean) {
    this.getServiceList();
  }

  ngOnDestroy() {
    if (this.serviceListSub$) this.serviceListSub$.unsubscribe();
    if (this.dialogSub$) this.dialogSub$.unsubscribe();
  }
}
