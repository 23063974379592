import { Component, Inject, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MAT_SELECT_CONFIG } from "@angular/material/select";
export const MY_FORMATS = {
  parse: {
    dateInput: "LL",
  },
  display: {
    dateInput: "LL",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};
export interface dateSelect {
  startDate: String;
  endData: String;
}
@Component({
  selector: "app-filter-dialog",
  templateUrl: "./filter-dialog.component.html",
  styleUrls: ["./filter-dialog.component.scss"],
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: "filterSelectCustom" },
    },
    { provide: MAT_DATE_LOCALE, useValue: "ar" },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class FilterDialogComponent implements OnInit {
  isLoading = false;
  today = new Date();
  month = new Date().getMonth();
  year = new Date().getFullYear();
  selectDate: dateSelect = {
    startDate: "",
    endData: "",
  };

  constructor(
    public dialogRef: MatDialogRef<FilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void { }

  // filter form submit
  filter(filterFormForm: NgForm) {
    let obj: any = { ...filterFormForm.form.value };
    obj.dateFromValue = filterFormForm.form.value.dateFromValue
      ? new Date(filterFormForm.form.value.dateFromValue).toJSON()
      : undefined;
    obj.dateToValue = filterFormForm.form.value.dateFromValue
      ? new Date(filterFormForm.form.value.dateToValue).toJSON()
      : undefined;

    if (obj.filterBy === "day") {
      this.selectDate.startDate = new Date(
        this.today.setHours(0, 0, 1, 0)
      ).toJSON();
      this.selectDate.endData = new Date().toJSON();
    }
    if (obj.filterBy === "week") this.getCurrentWeek();
    if (obj.filterBy === "month") this.getCurrentMonth();

    if (obj.filterBy == "specific" && obj.dateToValue && obj.dateFromValue) {
      obj.dateToValue = new Date(
        new Date(obj.dateToValue).setHours(0, 0, 1, 0)
      ).toJSON();
      obj.dateFromValue = new Date(
        new Date(obj.dateFromValue).setHours(0, 0, 1, 0)
      ).toJSON();
    } else if (obj.filterBy) {
      obj.dateToValue = this.selectDate.endData;
      obj.dateFromValue = this.selectDate.startDate;
    }

    this.dialogRef.close(obj);
  }

  getCurrentWeek() {
    this.selectDate.startDate = new Date(
      new Date(
        new Date(this.today.getTime() - 6 * 24 * 60 * 60 * 1000)
      ).setHours(0, 0, 1, 0)
    ).toJSON();
    this.selectDate.endData = new Date().toJSON();
  }

  getCurrentMonth() {
    this.selectDate.startDate = new Date(this.year, this.month, 1).toJSON();
    this.selectDate.endData = new Date().toJSON();
  }

  closeDialog(data?: boolean) {
    this.dialogRef.close(data);
  }

  ngOnDestroy() { }
}
