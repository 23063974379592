import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subscription } from "rxjs";

import { CasesService } from "src/app/cms/services/cases.service";
import { CaseSection } from "src/app/shared/models/interfaces";
import {
  addDuplicatedError,
  removeDuplicatedErrorOnUpdate,
} from "src/app/shared/helpers/formControlers";

@Component({
  selector: "app-save-case-section-dialog",
  templateUrl: "./save-case-section-dialog.component.html",
  styleUrls: ["./save-case-section-dialog.component.scss"],
})
export class SaveCaseSectionDialogComponent implements OnInit, OnDestroy {
  isLoading = false;
  titleSub: Subscription;

  saveCaseSectionForm = this._fb.group({
    title: [this.data.sectionTitle, Validators.required],
    case_id: this.data.caseId,
    order: 0, // TODO: set order dynamically
    status: true, // TODO: set status dynamically
  });

  constructor(
    public dialogRef: MatDialogRef<SaveCaseSectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fb: FormBuilder,
    private _casesService: CasesService
  ) {}

  ngOnInit(): void {
    this.titleSub = removeDuplicatedErrorOnUpdate(
      this.saveCaseSectionForm.controls.title
    );
  }

  private get _sectionData() {
    return this.saveCaseSectionForm.value;
  }

  async saveSection() {
    this.isLoading = true;

    try {
      if (this.data.sectionId) {
        await this._casesService
          .updateCaseSection(this._sectionData, this.data.sectionId)
          .toPromise();
        this.closeDialog({ ...this._sectionData, id: this.data.sectionId });
      } else {
        const savedCase = await this._casesService
          .addCaseSection(this._sectionData)
          .toPromise();

        this.closeDialog(savedCase);
      }
    } catch (err) {
      if (
        err.status == 400 &&
        err.error.error.message == "There is already section with that name"
      ) {
        addDuplicatedError(this.saveCaseSectionForm.controls.title);
      }
    }
    this.isLoading = false;
  }

  closeDialog(data?: Partial<CaseSection>) {
    this.dialogRef.close(data);
  }

  ngOnDestroy() {
    this.titleSub.unsubscribe();
  }
}
