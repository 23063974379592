import { Component, OnInit } from "@angular/core";

import { AuthService } from "src/app/core/auth.service";
import {
  TableService,
  TableSchema,
  TableTypes,
  DataTableComponentBase,
  Filter,
} from "@kortobaa-front/k-ng-datatable";

@Component({
  selector: "app-faq-list",
  templateUrl: "./faq-list.component.html",
  styleUrls: ["./faq-list.component.scss"],
})
export class FaqListComponent extends DataTableComponentBase implements OnInit {
  moduleName = "faq";
  url = "faq";
  categories = [];

  displayedColumns: TableSchema[] = [
    {
      title: "ID",
      id: "id",
      type: TableTypes.number,
      editable: false,
    },
    {
      title: "question",
      id: "question",
      type: TableTypes.text,
      editable: true,
    },
    {
      title: "answer",
      id: "answer",
      type: TableTypes.textarea,
      editable: true,
    },
    {
      title: "category",
      id: "categoryId",
      type: TableTypes.select,
      list: this.categories,
      editable: true,
    },
    {
      title: "Status",
      id: "status",
      type: TableTypes.booleanNumber,
      editable: true,
    },
  ];
  dataSource;
  isDataLoading = false;

  constructor(
    private tableService: TableService,
    public authService: AuthService
  ) {
    super();
    this.tableService.tableUrl = "faqs";
  }

  async ngOnInit() {
    //
  }

  async getData(filters?: Filter) {
    this.isDataLoading = true;
    try {
      const faqList = await this.tableService.getData(filters).toPromise();
      let categories: any = [];
      this.tableService.tableUrl = "faq-categories";
      categories = await this.tableService
        .getData({ where: { and: [{}] }, fields: { id: true, title: true } })
        .toPromise();
      categories.forEach((each) => {
        this.categories.push({
          title: each.title,
          value: each.id,
        });
      });
      this.dataSource = faqList;
    } catch (err) {
      // console.error(err);
    }
    this.tableService.tableUrl = "faqs";
    this.isDataLoading = false;
  }

  getDataForAction(data) {
    this.tableService.SaveItem(data).subscribe(
      (row: any) => {
        this.tableService.redirect(this.url);
      },
      (error) => {
        alert(error.error.error.message);
      }
    );
    //console.log('from main ',data)
  }
}
