import { Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { TableService } from "@kortobaa-front/k-ng-datatable";

import { UserRoles } from "src/app/shared/models/interfaces";

@Component({
  selector: "app-assign-dialog",
  templateUrl: "./assign-dialog.component.html",
  styleUrls: ["./assign-dialog.component.scss"],
})
export class AssignDialogComponent {
  selectedAssignees: any[] = [];
  assignees: any[] = [];
  @ViewChild("container") container!: ElementRef;
  mouseDown = false;
  startX!: any;
  scrollLeft!: any;
  myControl = new FormControl("");
  filteredOptions!: Observable<any[]>;

  constructor(
    private _dialogRef: MatDialogRef<AssignDialogComponent>,
    private _tableService: TableService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this._getAllAssigneeUsers();

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      map((value) => this.filter(value || ""))
    );
  }

  private async _getAllAssigneeUsers() {
    this._tableService.tableUrl = "admin/users/all";

    const allAssigneeUsers = (await this._tableService
      .SaveItem({
        assignee_type: this.data.assigneeType,
      })
      .toPromise()) as [];

    this.assignees = allAssigneeUsers;
    this.myControl.setValue("");
  }

  // select assignee
  selectAssignee(assignee: any) {
    const isAdded = this.selectedAssignees.includes(assignee);
    if (isAdded) return;

    if (this.data.assigneeType === UserRoles.supervisor) {
      this.selectedAssignees[0] = assignee;
    } else {
      this.selectedAssignees.push(assignee);
    }

    this.myControl.setValue(" ");
  }

  // remove assignee
  deSelectAssignee(assignee: any) {
    this.selectedAssignees = this.selectedAssignees.filter(
      (item) => item != assignee
    );
  }

  // push assignee and send it to api
  submitAssignees() {
    const assigneeIds = this.selectedAssignees.map(
      (assignee: any) => assignee.id
    );

    this._dialogRef.close({
      assigneeIds: assigneeIds,
    });
  }

  // mouse move to drag
  mousemove(e: any) {
    e.preventDefault();
    if (!this.mouseDown) {
      return;
    }
    const x = e.pageX - e.target.offsetLeft;
    const scroll = x - this.startX;
    e.target.scrollLeft = this.scrollLeft - scroll;
  }

  // start drag
  startDragging(e: any) {
    this.mouseDown = true;
    this.startX = e.pageX - e.target.offsetLeft;
    this.scrollLeft = e.target.scrollLeft;
  }

  // stop drag
  stopDragging(e: any) {
    this.mouseDown = false;
  }

  // filter assignees
  filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.assignees.filter((option) =>
      option.username.toLowerCase().includes(filterValue)
    );
  }
}
