import { Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Service } from "src/app/shared/models/interfaces";
import { NgForm } from "@angular/forms";
import { ServicesService } from "../../services/services.service";
import { GenerateFormDataService } from "src/app/shared/services/generate-form-data.service";
import { DataService } from "src/app/shared/services/data.service";

@Component({
  selector: "app-add-service-dialog",
  templateUrl: "./add-service-dialog.component.html",
  styleUrls: ["./add-service-dialog.component.scss"],
})
export class AddServiceDialogComponent {
  isLoading = false;
  discount;
  serviceImg!: File | null;
  serviceImgUrl: string | ArrayBuffer | null = "";
  MAXIMUM_IMAGE_SIZE: number = 2097152;
  isImageInvalid: boolean = false;
  @ViewChild("service_img") serviceImageInput!: ElementRef;
  imgUrl = this.data?.service?.imageUrl;
  serviceData: Service = {
    title: this.data?.service?.title ? this.data.service.title : "",
    file: null as File,
    description: this.data?.service?.description
      ? this.data.service.description
      : "",
    enabled: this.data?.service ? this.data.service.enabled : true,
    type: this.data?.service?.type ? this.data.service.type : "case",
    nextTwoCasesDiscountPercent: this.data?.service?.nextTwoCasesDiscountPercent
      ? this.data.service.nextTwoCasesDiscountPercent
      : null,
    discount: null,
  };

  constructor(
    public dialogRef: MatDialogRef<AddServiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _servicesService: ServicesService,
    private _generateFormData: GenerateFormDataService,
    public dataService: DataService
  ) {
    this.serviceData["discount"] = this.serviceData.nextTwoCasesDiscountPercent
      ? true
      : false;
    this.serviceImgUrl = this.data?.service?.imageUrl
      ? this.data.service.imageUrl
      : null;
  }

  async addServiceForm(serviceForm: NgForm) {
    this.isLoading = true;
    let obj: Service = {
      title: serviceForm.form.value.title,
      file: this.serviceImg,
      description: serviceForm.form.value.description,
      enabled: serviceForm.form.value.enabled,
      type: serviceForm.form.value.type,
    };
    if (!!serviceForm.form.value.discount) {
      obj["nextTwoCasesDiscountPercent"] =
        +serviceForm.form.value.nextTwoCasesDiscountPercent;
    }
    if (this.data?.service?.title) {
      if (this.data?.service?.imageUrl && !this.serviceImg) {
        delete obj.file;
      }
      if (this.data?.service.nextTwoCasesDiscountPercent && serviceForm.form.value.discount == false) {
        obj["nextTwoCasesDiscountPercent"] = null
      }
      const serviceFormData = this._generateFormData.generateFormData(obj);
      try {
        const savedService = await this._servicesService
          .editService(serviceFormData, this.data?.service?.id)
          .toPromise();
        this.closeDialog(true);
      } catch (err) {
        // console.error(err);
        if (err.error.error.statusCode == 400) {
          this.dataService.notification("لا يمكنك تعطيل الخدمة في الوقت الحالي لوجود إشتراكات قيد الإستكمال عليها", 'error-snackbar');
        }
      }
    } else {
      const serviceFormData = this._generateFormData.generateFormData(obj);
      try {
        const savedService = await this._servicesService
          .addNewService(serviceFormData)
          .toPromise();
        this.closeDialog(true);
      } catch (err) {
        // console.error(err);
        if (err.error.error.statusCode == 400) {
          this.dataService.notification(err.error.error.message, 'error-snackbar');
        }
      }
    }
    this.isLoading = false;
  }

  //upload image to submit the user profile
  onImgUploaded(event: any) {
    const uploadedImg = event.target.files[0];
    this.serviceImg = uploadedImg;
    // Not allow to upload image which size is equal or more than 2MB
    this.isImageInvalid = uploadedImg.size >= this.MAXIMUM_IMAGE_SIZE;
    const reader = new FileReader();
    reader.readAsDataURL(uploadedImg);
    reader.onload = (_event) => {
      this.serviceImgUrl = reader.result;
    };
  }

  //delete upload image before submitting
  removeImage() {
    this.serviceImgUrl = "";
    this.imgUrl = null;
    this.serviceImg = null;
    this.isImageInvalid = false;
    this.serviceImageInput.nativeElement.value = "";
  }

  //trigger the file input by click attach icon
  triggerUploadServiceImg() {
    this.serviceImageInput.nativeElement.click();
  }

  closeDialog(data?: boolean) {
    this.dialogRef.close(data);
  }

  ngOnDestroy() {
    this.isImageInvalid = false;
  }
}
