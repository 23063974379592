import { Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { TranslateService } from "@ngx-translate/core";

import { DataService } from "src/app/shared/services/data.service";
import { AuthService } from "src/app/core/auth.service";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { SidenavService } from "../sidenav.service";
import { environment } from "src/environments/environment";
import { ConfirmDialogComponent } from "src/app/shared/components/confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent {
  @ViewChild("sidenav", { static: true }) public sidenav: MatSidenav;
  showProfile = false;
  panelOpenState = true;
  currentLang: string = "en";

  constructor(
    public auth: AuthService,
    private router: Router,
    public dataService: DataService,
    private breakpointObserver: BreakpointObserver,
    private _translate: TranslateService,
    private sidenavService: SidenavService,
    private _dialog: MatDialog
  ) {
    this.currentLang = this._translate.currentLang;

    breakpointObserver.observe([Breakpoints.Handset]).subscribe((result) => {
      if (result.matches) {
        this.panelOpenState = false;
      }
    });

    breakpointObserver.observe([Breakpoints.Web]).subscribe((result) => {
      if (result.matches) {
        this.panelOpenState = true;
      }
    });
  }

  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.sidenav);
  }

  showUserProfile() {
    //this.router.navigate(['/profile',this.auth.currentUser.id]);
  }

  // NOTE: don't remove maybe add multiple language in the future
  private useLanguage(language: string) {
    localStorage.setItem("defaultLanguage", language);
    this._translate.use(language);
    this.currentLang = language;
  }

  getVersion() {
    return environment.version;
  }

  async logout() {
    const isLogout: boolean = await this._dialog
      .open(ConfirmDialogComponent, {
        direction: this._translate.currentLang == "ar" ? "rtl" : "ltr",
        panelClass: "logout-dialog",
        width: "25vw",
        data: {
          title: this._translate.instant("login.logout"),
          content: this._translate.instant("login.are-you-want-logout"),
          icon: "logout",
        },
      })
      .afterClosed()
      .toPromise();

    if (isLogout) {
      this.router.navigate(["/logout"]);
    }
  }
}
