import { Component, Inject, OnDestroy } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { CasesService } from "src/app/cms/services/cases.service";
import { Case } from "src/app/shared/models/interfaces";

@Component({
  selector: "app-save-case-dialog",
  templateUrl: "./save-case-dialog.component.html",
  styleUrls: ["./save-case-dialog.component.scss"],
})
export class SaveCaseDialogComponent implements OnDestroy {
  actualImage: string | ArrayBuffer = "";

  readonly MAXIMUM_IMAGE_SIZE = 2097152;
  isLoading = false;
  isImageInvalid = false;
  imgUrl = this.data.caseData?.image_url;

  caseData = {
    title: this.data.caseData?.title,
    image: null as File,
    order: 0, // TODO: set order dynamically
    status: this.data.caseData?.status,
    hasReconciliation: this.data.caseData?.hasReconciliation || true, // TODO remove the truth condition,
  };

  constructor(
    public dialogRef: MatDialogRef<SaveCaseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _caseService: CasesService
  ) {}

  async saveCase() {
    this.isLoading = true;

    const caseFormData = new FormData();
    for (const [key, value] of Object.entries(this.caseData)) {
      caseFormData.append(key, value);
    }

    try {
      if (this.data.caseData?.id) {
        const updatedCase = await this._caseService
          .updateCase(caseFormData, this.data.caseData?.id)
          .toPromise();

        this.closeDialog(updatedCase);
      } else {
        const savedCase = await this._caseService
          .addCase(caseFormData)
          .toPromise();

        this.closeDialog(savedCase);
      }
    } catch (err) {}
    this.isLoading = false;
  }

  onImgUploaded(event) {
    const uploadedImg = event.target.files[0];
    this.caseData.image = uploadedImg;
    // Not allow to upload image which size is equal or more than 2MB
    this.isImageInvalid = uploadedImg.size >= this.MAXIMUM_IMAGE_SIZE;

    const reader = new FileReader();
    reader.readAsDataURL(uploadedImg);
    reader.onload = (_event) => {
      this.actualImage = reader.result;
    };
  }

  closeDialog(data?: Partial<Case>) {
    this.dialogRef.close(data);
  }

  ngOnDestroy() {
    this.isImageInvalid = false;
  }
}
