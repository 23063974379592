import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import {
  PlanFeatures,
  PlanFeaturesEntity,
} from "src/app/shared/models/interfaces";
import { MAT_SELECT_CONFIG } from "@angular/material/select";
import { PlansService } from "../../services/plans.service";
import { ArabicDatePipe } from "src/app/shared/pipes/arabic-date-pipe.pipe";
import { DataService } from "src/app/shared/services/data.service";

@Component({
  selector: "app-add-plan-dialog",
  templateUrl: "./add-plan-dialog.component.html",
  styleUrls: ["./add-plan-dialog.component.scss"],
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: "timeSelectCustom" },
    },
  ],
})
export class AddPlanDialogComponent implements OnInit {
  isLoading = false;
  planForm!: FormGroup;
  planFeatures: PlanFeaturesEntity[] = [
    {
      mainTitle: "مزايا الخطة ",
      title: "",
      type: "feature",
      icon: "done",
      included: true,
      plans: [],
    },
    {
      mainTitle: "مزايا خارج الخطة ",
      title: "",
      type: "feature",
      icon: "close",
      included: false,
      plans: [],
    },
    {
      mainTitle: "ملاحظات ",
      title: "",
      type: "note",
      plans: [],
    },
  ];

  days = [
    {
      value: "sat",
      title: "السبت",
    },
    {
      value: "sun",
      title: "الأحد",
    },
    {
      value: "mon",
      title: "الأتنين",
    },
    {
      value: "tue",
      title: "الثلاثاء",
    },
    {
      value: "wed",
      title: "الأربعاء",
    },
    {
      value: "thu",
      title: "الخميس",
    },
    {
      value: "fri",
      title: "الجمعة",
    },
  ];

  date: Date = new Date();
  filterPipeTime = new ArabicDatePipe();
  planObj: PlanFeatures;

  constructor(
    public dialogRef: MatDialogRef<AddPlanDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fb: FormBuilder,
    private _plansService: PlansService,
    private _cdr: ChangeDetectorRef,
    public dataService: DataService
  ) { }

  ngOnInit() {
    this.planForm = this._fb.group({
      title: [this.data.plan ? this.data.plan.title : ""],
      price: [this.data.plan ? this.data.plan.price : undefined],
      preFeesAmountStatus: [
        this.data.plan ? (this.data.plan.preFeesAmont ? true : false) : false,
      ],
      preFeesAmont: [
        this.data.plan
          ? this.data.plan.preFeesAmont
            ? +this.data.plan.preFeesAmont
            : undefined
          : undefined,
      ],
      enabled: [this.data.plan ? this.data.plan.enabled : true],
      endsInStatus: [
        this.data.plan ? (this.data.plan.endsIn ? true : false) : false,
      ],
      endsIn: [
        this.data.plan
          ? this.data.plan.endsIn
            ? +this.data.plan.endsIn
            : undefined
          : undefined,
      ],
      endsInUnit: [
        this.data.plan
          ? this.data.plan.endsInUnit
            ? this.data.plan.endsInUnit
            : "day"
          : "day",
      ],
      availabilityTimeStatus: [
        this.data.plan
          ? this.data.plan.availabilityDaysFrom
            ? true
            : false
          : false,
      ],
      availabilityDaysFrom: [
        this.data.plan
          ? this.data.plan.availabilityDaysFrom
            ? this.data.plan.availabilityDaysFrom
            : undefined
          : undefined,
      ],
      availabilityDaysTo: [
        this.data.plan
          ? this.data.plan.availabilityDaysTo
            ? this.data.plan.availabilityDaysTo
            : undefined
          : undefined,
      ],
      availabilityTimeFrom: [undefined],
      availabilityTimeTo: [undefined],
      serviceId: this.data.plan
        ? this.data.plan.serviceId
        : this.data.serviceId,
      timeLogHourFrom: [
        this.data.plan
          ? this.data.plan.availabilityDaysTo
            ? this.filterPipeTime.transform(
              this.data.plan.availabilityTimeFrom,
              "h",
              "en"
            )
            : ""
          : "",
      ],
      timeLogMinFrom: [
        this.data.plan
          ? this.data.plan.availabilityDaysTo
            ? this.filterPipeTime.transform(
              this.data.plan.availabilityTimeFrom,
              "mm",
              "en"
            )
            : ""
          : "",
      ],
      timeLogFrom: [
        this.data.plan
          ? this.data.plan.availabilityDaysTo
            ? this.filterPipeTime.transform(
              this.data.plan.availabilityTimeFrom,
              "a"
            )
            : "م"
          : "م",
      ],
      timeLogHourTo: [
        this.data.plan
          ? this.data.plan.availabilityDaysTo
            ? this.filterPipeTime.transform(
              this.data.plan.availabilityTimeTo,
              "h",
              "en"
            )
            : ""
          : "",
      ],
      timeLogMinTo: [
        this.data.plan
          ? this.data.plan.availabilityDaysTo
            ? this.filterPipeTime.transform(
              this.data.plan.availabilityTimeTo,
              "mm",
              "en"
            )
            : ""
          : "",
      ],
      timeLogTo: [
        this.data.plan
          ? this.data.plan.availabilityDaysTo
            ? this.filterPipeTime.transform(
              this.data.plan.availabilityTimeTo,
              "a"
            )
            : "م"
          : "م",
      ],
      plansFeatures: this._fb.array(
        this.data.plan && this.data.plan.planFeatures
          ? [
            ...this.data.plan.planFeatures.filter((plan) => {
              if (plan.type == "feature" && plan.included == true) {
                return plan;
              }
            }),
          ].map((plan) => {
            return plan.title;
          })
          : []
      ),
      plansNotFeatures: this._fb.array(
        this.data.plan && this.data.plan.planFeatures
          ? [
            ...this.data.plan.planFeatures.filter((plan) => {
              if (plan.type == "feature" && plan.included == false) {
                return plan;
              }
            }),
          ].map((plan) => {
            return plan.title;
          })
          : []
      ),
      plansNotes: this._fb.array(
        this.data.plan && this.data.plan.planFeatures
          ? [
            ...this.data.plan.planFeatures.filter((plan) => {
              if (plan.type == "note") {
                return plan;
              }
            }),
          ].map((plan) => {
            return plan.title;
          })
          : []
      ),
    });
  }

  // check form
  ngAfterContentChecked(): void {
    if (this.planForm.invalid && this.planForm.touched) {
      this.planForm.markAllAsTouched();
    }
  }

  // handle input check decimal
  handleInput(value: string) {
    if (
      (this.planForm.controls.endsIn.value &&
        +this.planForm.controls.endsIn.value == 0) ||
      !/^\d{1,}$/.test(this.planForm.controls.endsIn.value)
    ) {
      this.planForm.get(value).setErrors({ showError: true });
      this._cdr.detectChanges();
    }
  }

  // valid time
  validTime(flag: boolean, time: any, value: string) {
    if (flag && +this.planForm.controls.timeLogMinFrom.value == 0)
      this.planForm.controls.timeLogMinFrom.setValue("00");
    if (!flag && +this.planForm.controls.timeLogMinTo.value == 0)
      this.planForm.controls.timeLogMinTo.setValue("00");
    // if (value == "hour") {
    //   if (+time >= 12) {
    //     this.planForm.controls.timeLogFrom.setValue("م");
    //     if (!flag) this.planForm.controls.timeLogTo.setValue("م");
    //   } else {
    //     this.planForm.controls.timeLogFrom.setValue("ص");
    //     if (!flag) this.planForm.controls.timeLogTo.setValue("ص");
    //     if (this.planForm.controls.timeLogFrom.value == "م") {
    //       console.log(
    //         this.planForm.controls.timeLogHourFrom.value,
    //         this.planForm.controls.timeLogFrom.value, 24 - this.planForm.controls.timeLogHourFrom.value
    //       );
    //       this.planForm.controls.timeLogHourFrom.setValue(
    //         24 - this.planForm.controls.timeLogHourFrom.value
    //       )
    //     }
    //     if (this.planForm.controls.timeLogTo.value == "م") {
    //       console.log(
    //         this.planForm.controls.timeLogHourTo.value,
    //         this.planForm.controls.timeLogTo.value, 24 - this.planForm.controls.timeLogHourTo.value
    //       );
    //       this.planForm.controls.timeLogHourTo.setValue(
    //         24 - this.planForm.controls.timeLogHourTo.value
    //       );
    //     }
    //   }
    // }
    return value == "hour"
      ? +time > 24
        ? flag
          ? this.planForm.controls.timeLogHourFrom.setValue("")
          : this.planForm.controls.timeLogHourTo.setValue("")
        : flag
          ? this.planForm.controls.timeLogHourFrom.setValue(+time)
          : this.planForm.controls.timeLogHourTo.setValue(+time)
      : +time > 59
        ? flag
          ? this.planForm.controls.timeLogMinFrom.setValue("")
          : this.planForm.controls.timeLogMinTo.setValue("")
        : flag
          ? this.planForm.controls.timeLogMinFrom.setValue(+time)
          : this.planForm.controls.timeLogMinTo.setValue(+time);
  }

  // add new plan feature
  addNewFeature(value: string) {
    const fields =
      value == "feature"
        ? this.plansFeatures
        : value == "notFeature"
          ? this.plansNotFeatures
          : this.plansNotes;
    fields.push(this.createField());
  }

  // create new features
  createField() {
    return this._fb.control("");
  }

  // get feature
  get plansFeatures() {
    return this.planForm.controls["plansFeatures"] as FormArray;
  }

  // get feature not included
  get plansNotFeatures() {
    return this.planForm.controls["plansNotFeatures"] as FormArray;
  }

  // remove notes plan
  get plansNotes() {
    return this.planForm.controls["plansNotes"] as FormArray;
  }

  // remove feature
  removeFeature(value: string, fieldIndex: number) {
    (value == "feature"
      ? this.plansFeatures
      : value == "notFeature"
        ? this.plansNotFeatures
        : this.plansNotes
    ).removeAt(fieldIndex);
  }

  // handle add plan
  async addNewPlanForm() {
    this.isLoading = true;
    this.planObj = {
      title: this.planForm.value.title,
      price: +this.planForm.value.price,
      enabled: this.planForm.value.enabled,
      serviceId: this.planForm.value.serviceId,
      planFeatures: [
        ...this.planForm.value.plansFeatures.map((plan: PlanFeaturesEntity) => {
          return {
            title: plan,
            included: true,
            type: "feature",
          };
        }),
        ...this.planForm.value.plansNotFeatures.map(
          (plan: PlanFeaturesEntity) => {
            return {
              title: plan,
              included: false,
              type: "feature",
            };
          }
        ),
        ...this.planForm.value.plansNotes.map((plan: PlanFeaturesEntity) => {
          return {
            title: plan,
            type: "note",
          };
        }),
      ],
    };

    // check fees amount if available
    if (this.planForm.value.preFeesAmountStatus) {
      this.planObj["preFeesAmont"] = +this.planForm.value.preFeesAmont;
    }

    // check end time  if available
    if (this.planForm.controls.endsInStatus.value) {
      this.planObj["endsIn"] = +this.planForm.value.endsIn;
      this.planObj["endsInUnit"] = this.planForm.value.endsInUnit;
    }

    // check availability time  if available
    if (this.planForm.value.availabilityTimeStatus) {
      this.planObj["availabilityTimeFrom"] = new Date(
        this.date.setHours(
          +this.planForm.controls.timeLogHourFrom.value <= 12 &&
            this.planForm.controls.timeLogFrom.value == "م"
            ? 12 + +this.planForm.controls.timeLogHourFrom.value
            : +this.planForm.controls.timeLogHourFrom.value,
          +this.planForm.controls.timeLogMinFrom.value,
          0,
          0
        )
      ).toJSON();
      this.planObj["availabilityTimeTo"] = new Date(
        this.date.setHours(
          +this.planForm.controls.timeLogHourTo.value <= 12 &&
            this.planForm.controls.timeLogTo.value == "م"
            ? 12 + +this.planForm.controls.timeLogHourTo.value
            : +this.planForm.controls.timeLogHourTo.value,
          +this.planForm.controls.timeLogMinTo.value,
          0,
          0
        )
      ).toJSON();
      this.planObj["availabilityDaysFrom"] =
        this.planForm.value.availabilityDaysFrom;
      this.planObj["availabilityDaysTo"] =
        this.planForm.value.availabilityDaysTo;
    }

    if (this.data.plan) {
      delete this.planObj["serviceId"];
      if (this.data.plan.preFeesAmont && this.planForm.value.preFeesAmountStatus == false) {
        this.planObj["preFeesAmont"] = null;
      }
      if (this.data.plan.endsIn && this.planForm.value.endsInStatus == false) {
        this.planObj["endsIn"] = null;
        this.planObj["endsInUnit"] = null;
      }
      if (this.data.plan.availabilityTimeFrom && this.planForm.value.availabilityTimeStatus == false) {
        this.planObj["availabilityTimeFrom"] = null;
        this.planObj["availabilityTimeTo"] = null;
        this.planObj["availabilityDaysFrom"] = null;
        this.planObj["availabilityDaysTo"] = null;
      }
      // handle edit plan
      try {
        await this._plansService
          .editPlan(this.planObj, this.data.plan.id)
          .toPromise();
        this.closeDialog(true);
      } catch (err) {
        if (err.error.error.statusCode == 400) {
          this.dataService.notification("لا يمكنك تعطيل الخطة في الوقت الحالي لوجود إشتراكات قيد الإستكمال عليها", 'error-snackbar');
        }
      }
    } else {
      // handle add new plan
      try {
        await this._plansService.addNewPlan(this.planObj).toPromise();
        this.closeDialog(true);
      } catch (err) {
        if (err.error.error.statusCode == 400) {
          this.dataService.notification(err.error.error.message, 'error-snackbar');
        }
      }
    }

    this.isLoading = false;
  }

  closeDialog(data?: boolean) {
    this.dialogRef.close(data);
  }
}
