export const formatAMPM = (date) => {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};
export const getDate = (date) => {
  const newData = new Date(date);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return newData.toLocaleDateString();
  //d.toLocaleDateString('ar-EG', options);
};

export const formatDate = (date) => {
  const newData = new Date(date);
  var monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  var day = newData.getDate();
  var monthIndex = newData.getMonth();
  var year = newData.getFullYear();
  var hours = newData.getHours();
  var minutes = newData.getMinutes();

  return (
    day +
    " " +
    monthNames[monthIndex] +
    " " +
    year +
    " - " +
    hours +
    ":" +
    minutes
  );
};

export const dateToMinutes = (startDate: any, endDate: any) => {
  const startDateVar: any = new Date(endDate);
  const endDateVar: any = new Date(startDate);
  const time = startDateVar - endDateVar;
  let dif = Math.round(time / 1000 / 60);
  if (dif === 0) dif = 1;
  return dif;
};

export const getFullDate = (date: string, locale: string) => {
  return new Date(date).toLocaleTimeString(`${locale}-EG`, {
    timeZone: "Asia/Riyadh",
    hour: "2-digit",
    minute: "2-digit",
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

export const getDateWithoutTime = (date: string, locale: string) => {
  return new Date(date).toLocaleTimeString(`${locale}-EG`, {
    timeZone: "Asia/Riyadh",
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

export const convertDate = (date: string, locale: string) => {
  return new Date(date).toLocaleTimeString(`${locale}-EG`, {
    timeZone: "Asia/Riyadh",
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const getDateByTimeZone = (date: string, locale: string) => {
  return new Date(date).toLocaleDateString(`${locale}-EG`, {
    timeZone: "Asia/Riyadh",
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short",
  });
};

export const getLongDateTime = (date: string, locale: string) => {
  return new Date(date).toLocaleString(`${locale}-EG`, {
    timeZone: "Asia/Riyadh",
    dateStyle: "short",
    timeStyle: "long",
    hour12: true,
  } as any);
};
