import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DeleteConfirmDialog } from "src/app/shared/delete-confirm-dialog/delete-confirm-dialog.component";
import {
  PlanFeatures,
  PlanFeaturesEntity,
} from "src/app/shared/models/interfaces";
import { ArabicDatePipe } from "src/app/shared/pipes/arabic-date-pipe.pipe";
import { PlansService } from "../../services/plans.service";
import { MatDialog } from "@angular/material/dialog";
import { DataService } from "src/app/shared/services/data.service";
import { AddPlanDialogComponent } from "../../dialogs/add-plan-dialog/add-plan-dialog.component";

@Component({
  selector: "app-plan-card",
  templateUrl: "./plan-card.component.html",
  styleUrls: ["./plan-card.component.scss"],
})
export class PlanCardComponent implements OnInit {
  @Input() plan: PlanFeatures;
  features: PlanFeaturesEntity[] = [];
  notes: PlanFeaturesEntity[] = [];
  filterPipeTime = new ArabicDatePipe();
  @Output() updatePlanEvent = new EventEmitter<true>();

  constructor(
    private _plansService: PlansService,
    private _dialog: MatDialog,
    public dataService: DataService
  ) { }

  ngOnInit(): void {
    this.filterItemsOfType();
  }

  days = [
    {
      value: "sat",
      title: "السبت",
    },
    {
      value: "sun",
      title: "الأحد",
    },
    {
      value: "mon",
      title: "الأتنين",
    },
    {
      value: "tue",
      title: "الثلاثاء",
    },
    {
      value: "wed",
      title: "الأربعاء",
    },
    {
      value: "thu",
      title: "الخميس",
    },
    {
      value: "fri",
      title: "الجمعة",
    },
  ];

  filterItemsOfType() {
    if (this.plan.planFeatures) {
      this.features = this.plan.planFeatures?.filter((plan) => {
        return plan.type == "feature";
      });
      this.notes = this.plan.planFeatures?.filter((plan) => {
        return plan.type == "note";
      });
    }
    if (this.plan.preFeesAmont) {
      this.notes.push({
        title: `لإتمام تقديم الطلب يتعين عليك دفع (${this.plan.preFeesAmont}) ريال وهي رسوم غير مستردة تمثل قيمة دراسة القضية من قبل المحامية، التي تتم من خلال التواصل معك لفحص القضية وتقييمها واعطائك المعلومات القانونية والنصائح الهامة لك. - عند قبول القضية بعد الانتهاء من دراستها، وفي حال رغبت بتوكيل المحامية للترافع عنك لحين صدور الحكم القضائي؛ فيتعين عليك دفع الرسوم المتبقية من الخدمة. `,
        type: "note",
      });
    }
    if (this.plan.endsIn) {
      this.notes.push({
        title: `يرجى العلم أن مدة الاستفادة من الخدمة ${this.plan.endsIn} ${this.plan.endsInUnit == "day"
          ? this.plan.endsIn >= 3 && this.plan.endsIn <= 10
            ? "أيام"
            : "يوم"
          : this.plan.endsInUnit == "weak"
            ? this.plan.endsIn >= 3 && this.plan.endsIn <= 10
              ? "أسابيع"
              : "أسبوع"
            : this.plan.endsIn >= 3 && this.plan.endsIn <= 10
              ? "شهور"
              : "شهر"
          }  فقط من تاريخ تقديم الطلب ودفع قيمة الخدمة، حيث سيتم تحديد موعد الجلسة معك خلال هذه الفترة، لذا يرجى التجاوب مع طلبات المحامية خلال هذه الفترة والتأكد من مدى تفرغك لإتمام المطلوب منك قبل تقديم الطلب، ونعتذر عن تحديد موعد الجلسة بعد انتهاء الفترة. `,
        type: "note",
      });
    }
    if (this.plan.availabilityDaysFrom) {
      this.notes.push({
        title: `يتم تحديد موعد الجلسة في أحد أيام الأسبوع من ${this.days.find((day) => {
          return day.value === this.plan.availabilityDaysFrom;
        }).title
          } حتى ${this.days.find((day) => {
            return day.value === this.plan.availabilityDaysTo;
          }).title
          } وخلال الفترة اليومية ما بين ${this.filterPipeTime.transform(
            this.plan.availabilityTimeFrom,
            "h:mm a"
          )} حتى ${this.filterPipeTime.transform(
            this.plan.availabilityTimeTo,
            "h:mm a"
          )}`,
        type: "note",
      });
    }
  }

  // delete plan
  openDeletePlanDialog(e: Event) {
    e.stopPropagation();
    this._dialog
      .open(DeleteConfirmDialog, {
        direction: "rtl",
        panelClass: "",
        autoFocus: false,
        data: {
          message: "هل انت متأكد من انك تريد حذف الخطة ؟",
          icon: "delete_forever",
        },
      })
      .afterClosed()
      .subscribe((accept: boolean) => {
        if (accept) {
          this._plansService.deletePlan(this.plan.id).subscribe({
            next: (data) => {
              this.dataService.notification(
                "تم حذف الخطة بنجاح",
                "success-snackbar"
              );
              this.updatePlanEvent.emit(true);
            },
            error: (err) => {
              console.log(err);
              this.dataService.notification(
                "لا يمكنك مسح الخطة في الوقت الحالي لوجود إشتراكات قيد الإستكمال عليها",
                "error-snackbar"
              );
            },
          });
        }
      });
  }

  // edit plan
  openEditPlanDialog(e: Event) {
    e.stopPropagation();
    this._dialog
      .open(AddPlanDialogComponent, {
        direction: "rtl",
        panelClass: "save-case-dialog",
        data: {
          plan: this.plan,
        },
      })
      .afterClosed()
      .subscribe((editService: any) => {
        if (editService) {
          this.dataService.notification(
            "تم تعديل الخطة بنجاح",
            "success-snackbar"
          );
          this.updatePlanEvent.emit(true);
        }
      });
  }
}
