import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CoreModule } from "./core/core.module";
import { AuthModule } from "./auth/auth.module";
import { UiModule } from "./ui/ui.module";
import { SharedModule } from "./shared/shared.module";
import { HomeModule } from "./home/home.module";
import { ReportsModule } from "./reports/reports.module";
import { RouterModule } from "@angular/router";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { AuthService } from "./core/auth.service";
import { CustomSnackbarComponent } from "./custom-snackbar/custom-snackbar.component";
import { CmsModule } from "./cms/cms.module";
import { UsersModule } from "./users/users.module";
import { UserCasesModule } from "./user-cases/user-cases.module";
import { ReportsComponent } from "./reports/reports/reports.component";
import { PaymentsModule } from "./payments/payments.module";
import { ServicesTypeModule } from "./services-types/servicesType.module";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
  //return new TranslateHttpLoader(http,'/admin/assets/i18n/');
}

@NgModule({
  declarations: [AppComponent, CustomSnackbarComponent],
  imports: [
    RouterModule.forRoot([]),
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
    CoreModule,
    AuthModule,
    UiModule,
    SharedModule,
    HomeModule,
    ReportsModule,
    MatSnackBarModule,
    CmsModule,
    UsersModule,
    UserCasesModule,
    PaymentsModule,
    ServicesTypeModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
  ],

  exports: [TranslateModule],
  providers: [AuthService],
  bootstrap: [AppComponent],
})
export class AppModule { }
