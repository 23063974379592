import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { getHeadersWithToken } from "src/app/shared/helpers/getHeadersWithToken";
import { environment } from "src/environments/environment";
import { Service } from "src/app/shared/models/interfaces";
import { ServiceList } from "src/app/shared/models/interfaces";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ServicesService {
  private readonly _api = environment.apiHost;

  constructor(private _http: HttpClient) { }

  // get service List
  getServiceList(): Observable<ServiceList[]> {
    return this._http.get<ServiceList[]>(this._api + `admin/services`, {
      headers: getHeadersWithToken(),
    });
  }

  // add new service
  addNewService(serviceData: FormData) {
    return this._http.post<Service>(this._api + `admin/services`, serviceData, {
      headers: getHeadersWithToken(),
    });
  }

  // delete service
  deleteService(id: number) {
    return this._http.delete(this._api + `admin/services/${id}`, {
      headers: getHeadersWithToken(),
    });
  }

  // get service by specific Id
  getServiceId(id: number) {
    return this._http.get<ServiceList>(this._api + `admin/services/${id}`, {
      headers: getHeadersWithToken(),
    });
  }

  // edit service by specific Id
  editService(serviceData: FormData, id: number) {
    return this._http.patch<Service>(
      this._api + `admin/services/${id}`,
      serviceData,
      {
        headers: getHeadersWithToken(),
      }
    );
  }

  // get upgrade value
  getUpgradeServiceValue() {
    return this._http.get<ServiceList>(this._api + `/admin/upgrade-service`, {
      headers: getHeadersWithToken(),
    });
  }

  // update upgrade service 
  updateUpgradeService(upgradeService: number) {
    return this._http.patch<Service>(
      this._api + `admin/upgrade-service`,
      { upgradeService: upgradeService },
      {
        headers: getHeadersWithToken(),
      }
    );
  }
}
