import { Component, OnInit, Inject, OnDestroy, ViewChild } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";

import { Subscription } from "rxjs";

import {
  TableService,
  TableSchema,
  TableTypes,
  DataTableComponentBase,
  DataTableComponent,
  Filter,
} from "@kortobaa-front/k-ng-datatable";

import { AuthService } from "src/app/core/auth.service";
import { RolesId, UserRoles } from "src/app/shared/models/interfaces";
import { getDate } from "src/app/shared/helpers/convertDate";

@Component({
  selector: "app-user-list",
  templateUrl: "./user-list.component.html",
  styleUrls: ["./user-list.component.scss"],
})
export class UserListComponent
  extends DataTableComponentBase
  implements OnInit, OnDestroy
{
  readonly UserRoles = UserRoles;
  moduleName = "users";
  url: string;
  isSearching: boolean = false;

  displayedColumns: TableSchema[] = [
    {
      title: "username",
      id: "username",
      type: TableTypes.text,
      editable: true,
    },
    {
      title: "nid",
      id: "nid",
      type: TableTypes.text,
      editable: true,
    },
    {
      title: "phone",
      id: "phone",
      type: TableTypes.text,
      editable: true,
    },
    {
      title: "email",
      id: "email",
      type: TableTypes.text,
      editable: true,
    },
    {
      title: "isDisabled",
      id: "isDisabled",
      type: TableTypes.booleanNumber,
      editable: true,
    },
  ];
  dataSource;
  isDataLoading = false;

  userRole: UserRoles;
  private _userRoleSub: Subscription;
  @ViewChild(DataTableComponent)
  private _dataTableComponent: DataTableComponent;

  constructor(
    public authService: AuthService,
    private _tableService: TableService,
    private dialog: MatDialog,
    private _activatedRoute: ActivatedRoute,
    private _router: Router
  ) {
    super();

    this._determineUserRole();
    this.url = this.userRole + "-management";

    if (this.userRole !== UserRoles.user) {
      this.displayedColumns.push({
        title: "cases-count",
        id: "userCasesCount",
        type: TableTypes.number,
        editable: false,
        notOnFilter: true,
      });
    }

    if (
      this.userRole !== UserRoles.user &&
      this.userRole !== UserRoles.conciliator
    ) {
      this.displayedColumns.push({
        title: "inquiries-count",
        id: "inquriesCount",
        type: TableTypes.number,
        editable: false,
        notOnFilter: true,
      });
    }

    if (this.userRole === UserRoles.lawyer) {
      this.displayedColumns.push({
        title: "worked-hours",
        id: "totalWorkedHours",
        type: TableTypes.number,
        editable: false,
        notOnFilter: true,
      });
    }

    this.displayedColumns.push({
      title: `${this.userRole}-data`,
      id: "test",
      type: TableTypes.extrnalinfo,
      notOnFilter: true,
    });
  }

  async ngOnInit() {}

  private _determineUserRole() {
    this._userRoleSub = this._activatedRoute.data.subscribe(
      ({ state }) => (this.userRole = state)
    );
  }

  async getData(filters?: Filter) {
    this.isDataLoading = true;

    this._tableService.tableUrl = `admin/usersByRole/${this.userRole}`;
    try {
      this.dataSource = (
        await this._tableService.getData<any>(filters).toPromise()
      ).map((user) => ({
        ...user,
        secondName: user.additionalData.secondName,
        thirdName: user.additionalData.thirdName,
        lastName: user.additionalData.lastName,
        isDisabled:!user.isDisabled,
        birthDate: user.additionalData.birthdate
          ? getDate(user.additionalData.birthdate)
          : "",
        totalWorkedHours: Math.trunc(user.totalWorkedMinutes / 60),
      }));

      if(Object.keys(this._dataTableComponent.backendFilter.where.and[0]).length !==0 || this._dataTableComponent.backendFilter.where.and.length > 1){
        this._dataTableComponent.tableDataLength = (
          await this._tableService
            .getDataCount(this._dataTableComponent.backendFilter.where)
            .toPromise()
        ).count;
      }
    } catch (error) {
      console.error(error);
    }
    this.isDataLoading = false;
  }

  async getExportData(data) {
    try {
      this._tableService.tableUrl = `admin/usersByRole/${this.userRole}`;
      let filters = { ...this._dataTableComponent.backendFilter };
      if (filters["limit"]) delete filters["limit"];
      if (filters["offset"]) delete filters["offset"];
      let exportData = (
        await this._tableService.getData<any>(filters).toPromise()
      ).map((user) => ({
        ...user,
        secondName: user.additionalData.secondName,
        thirdName: user.additionalData.thirdName,
        lastName: user.additionalData.lastName,
        birthDate: user.additionalData.birthdate
          ? getDate(user.additionalData.birthdate)
          : "",
        totalWorkedHours: Math.trunc(user.totalWorkedMinutes / 60),
      }));
      await this._dataTableComponent.downloadAllData(exportData);
    } catch (error) {
      console.error(error);
    }
  }

  async getDataForAction(data) {
    delete data["birthDate"];

    this._tableService.tableUrl = `/users`;

    this._tableService
      .SaveItem({ ...data, roleId: RolesId[this.userRole] })
      .subscribe(
        (_) => {
          this._tableService.redirect(this.url);
        },
        (error) => {
          alert(error.error.error.message);
        }
      );
  }

  async handleSearchChange(value: string) {
    // TODO: refactor all this logic, it's just a workaround to manipulate the data table component directly because of the poor API
    this.isDataLoading = true;
    if (!value) this._dataTableComponent.paginator.firstPage();

    const filter = {
      or: [
        { username: { regexp: `/.*${value}.*/i` } },
        { phone: { regexp: `/.*${value}.*/i` } },
        { email: { regexp: `/.*${value}.*/i` } },
        { nid: { regexp: `/.*${value}.*/i` } },
      ],
    };

    let roleId = 0;

    if (this.userRole == "user") roleId = RolesId.user;
    if (this.userRole == "supervisor") roleId = RolesId.supervisor;
    if (this.userRole == "lawyer") roleId = RolesId.lawyer;
    if (this.userRole == "conciliator") roleId = RolesId.conciliator;

    const role = {
      roleId: roleId,
    };

    this._dataTableComponent.backendFilter.where.and[1] = filter;

    this._dataTableComponent.backendFilter.where.and[2] = role;

    this.getData(this._dataTableComponent.backendFilter);

    this._dataTableComponent.tableDataLength = (
      await this._tableService
        .getDataCount(this._dataTableComponent.backendFilter.where)
        .toPromise()
    ).count;

    this.isDataLoading = false;
  }

  showUserData({ data }) {
    this._router.navigate(["show-user", this.userRole, data.id], {
      queryParams: { userName: data.username },
    });
  }

  ngOnDestroy(): void {
    this._userRoleSub.unsubscribe();
  }
}

// TODO: if no need to password dialog, remove it
@Component({
  selector: "dialog-overview-example-dialog",
  template: `
    <div mat-dialog-content>
      <p>{{ "PasswordDialog.Write password" | translate }}</p>
      <mat-form-field>
        <input matInput [(ngModel)]="data.password" />
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="onNoClick()">
        {{ "PasswordDialog.No Thanks" | translate }}
      </button>
      <button mat-button [mat-dialog-close]="data.password" cdkFocusInitial>
        {{ "PasswordDialog.Ok" | translate }}
      </button>
    </div>
  `,
})
export class DialogOverviewExampleDialog {
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
