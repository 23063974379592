import { HttpClient } from "@angular/common/http";
import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UsersService } from "src/app/auth/services/users.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-view-evaluation-dialog",
  templateUrl: "./view-evaluation-dialog.component.html",
  styleUrls: ["./view-evaluation-dialog.component.scss"],
})
export class ViewEvaluationDialogComponent implements OnInit {
  constructor(
    public _dialogRef: MatDialogRef<ViewEvaluationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _http: HttpClient,
    private _user: UsersService
  ) {
    this._http
      .get(
        `${environment.apiHost}/admin/${this.data.status == 'inquiry' ? 'inquiry-evaluations' : 'evaluations'}/${this.data.evaluationId}`,
        { headers: this._user.getHeaderswithToken() }
      )
      .subscribe((data: any) => {
        this.evaluation = data
        //converts answers to value
        data.answers.map((answer: any) => {
          let key: any = Object.keys(answer)[0];
          let value: any = Object.values(answer)[0];
          answer[key] = this.answerValues[value];
          this.answersObj[key] = this.answerValues[value];
          return answer
        })
        // console.log(this.answersObj)
        //attach answer to service
        this.answersService.map((answer: any) => {
          answer['answer'] = this.answersObj[answer?.id]
        })
        //attch answers to lawyer
        this.answersLawyer.map((answer: any) => {
          answer['answer'] = this.answersObj[answer?.id]
        })
      });
  }

  evaluation!: any;
  answersService = [
    {
      id: 0,
      question: "سهولة تقديم طلب عبر منصة عزوة ",
      answer: null,
    },
    {
      id: 1,
      question: "تلقي الرسالة عند إتمام تقديم الطلب ",
      answer: null,
    },
    {
      id: 2,
      question: "وضوح إجراءات وشروط قبول الطلبات و تقديم الخدمات",
      answer: null,
    },
    {
      id: 3,
      question:
        "مناسبة الوقت المستغرق منذ تقديم الطلب و حتى بدء الحصول على الخدمة",
      answer: null,
    },
    {
      id: 4,
      question:
        "المنصة تتيح إمكانية متابعة سير الخدمة و تسهل الاطلاع على المستجدات",
      answer: null,
    },
    {
      id: 5,
      question: "توفر الخصوصية وسرية البيانات",
      answer: null,
    },
    {
      id: 6,
      question: "سهولة الحصول على الدعم الفني عند مواجهة مشكلة تقنية",
      answer: null,
    },
  ];
  answersLawyer = [
    {
      id: 7,
      question: "مناسبة الآلية التي تم اتباعها في التواصل معك ",
      answer: null,
    },
    {
      id: 8,
      question: "الرغبة في الإصغاء لك والاستماع بعناية",
      answer: null,
    },
    {
      id: 9,
      question: "المدة التي قضاها في خدمتك كانت كافية",
      answer: null,
    },
    {
      id: 10,
      question: "شرح الرأي القانوني باتباع طريقة تستطيع فهمها",
      answer: null,
    },
    {
      id: 11,
      question: "الحرص على اطلاعك على جميع المستجدات",
      answer: null,
    },
    {
      id: 12,
      question: "الاحترام وحسن التعامل",
      answer: null,
    },
  ];
  answerValues = ["لا أعلم", "ضعيف", "متوسط", "جيد", "ممتاز"]
  answersObj = {}

  ngOnInit(): void { }

  close() {
    this._dialogRef.close()
  }
}
