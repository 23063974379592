import { Component } from "@angular/core";
import { DataService } from "src/app/shared/services/data.service";
import { fader, stepper } from "src/app/route-animations";
import { RouterOutlet } from "@angular/router";

@Component({
  selector: "app-main",
  template: `
    <div class="content-container">
      <div
        [ngClass]="{ 'content-area': dataService.url != '/login' }"
        class="content"
      >
        <router-outlet #outlet="outlet"></router-outlet>
      </div>
    </div>
  `,
  styles: [],
  /*animations:[
    fader,
  ]*/
})
export class MainComponent {
  constructor(public dataService: DataService) {}

  //[@routeAnimations]="prepareRoute(outlet)"
  /*prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData;
  }*/
}
