import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "../shared/shared.module";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatDividerModule } from "@angular/material/divider";
import { RouterModule } from "@angular/router";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatTabsModule } from "@angular/material/tabs";
import { MatDatepickerModule } from "@angular/material/datepicker";

// components
import { ServicesListComponent } from "./components/services-list/services-list.component";
import { ServicePlanPageComponent } from "./pages/service-plan-page/service-plan-page.component";
import { PlanCardComponent } from "./components/plan-card/plan-card.component";
import { AddPlanDialogComponent } from "./dialogs/add-plan-dialog/add-plan-dialog.component";
import { ServiceTypePageComponent } from "../services-types/pages/service-type-page/service-type-page.component";
import { AddServiceDialogComponent } from "./dialogs/add-service-dialog/add-service-dialog.component";
import { PromoCodePageComponent } from "./pages/promo-code-page/promo-code-page.component";
import { AddPromoCodeDialogComponent } from "./dialogs/add-promo-code-dialog/add-promo-code-dialog.component";
import { ActivePromoCodeComponent } from "./components/active-promo-code/active-promo-code.component";
import { ExpirePromoCodeComponent } from "./components/expire-promo-code/expire-promo-code.component";
import { ReactivatePromoCodeDialogComponent } from "./dialogs/reactivate-promo-code-dialog/reactivate-promo-code-dialog.component";
import { UpgradeServiceDialogComponent } from "./dialogs/upgrade-service-dialog/upgrade-service-dialog.component";

@NgModule({
  declarations: [
    ServiceTypePageComponent,
    ServicesListComponent,
    ServicePlanPageComponent,
    PlanCardComponent,
    AddServiceDialogComponent,
    AddPlanDialogComponent,
    PromoCodePageComponent,
    AddPromoCodeDialogComponent,
    ActivePromoCodeComponent,
    ExpirePromoCodeComponent,
    ReactivatePromoCodeDialogComponent,
    UpgradeServiceDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    RouterModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatInputModule,
    MatSelectModule,
    MatTabsModule,
    MatDatepickerModule,
  ],
})
export class ServicesTypeModule { }
