import { Injectable, EventEmitter } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  event = new EventEmitter<any>();

  constructor(private auth: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    //return true;
    if (localStorage.User) {
      const mainUrl = next.url[0].path;
      const userRoles = JSON.parse(localStorage.User).roles;
      const adminsArray = ["admin", "superAdmin"];
      if (
        !userRoles.includes(mainUrl.toLowerCase()) &&
        mainUrl.toLowerCase() != "home" &&
        !userRoles.some((roles) => adminsArray.includes(roles))
      ) {
        this.router.navigate(["/home"]);
        return false;
      }
      return true;
    } else {
      this.router.navigate(["/login"]);
      return false;
    }
  }
}
