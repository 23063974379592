import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { UserData } from "src/app/shared/models/interfaces";
import { getHeadersWithToken } from "src/app/shared/helpers/getHeadersWithToken";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class HandleUsersService {
  private readonly _api = environment.apiHost;

  constructor(private _http: HttpClient) {}

  async createNewUser(user: UserData) {
    const imagesFields: { [filed: string]: string } = {};

    for (const field in user.additionalData) {
      if (user.additionalData[field] instanceof File) {
        imagesFields[field] = user.additionalData[field];
        delete user.additionalData[field];
      }
    }

    const newUser = await this._http
      .post<UserData>(
        this._api + "/admin/user",
        {
          user,
          skipAdditionalData: true,
        },
        { headers: getHeadersWithToken() }
      )
      .toPromise();

    if (Object.keys(imagesFields).length) {
      for (const imageFiled in imagesFields) {
        const imageForm = new FormData();
        imageForm.append("image", imagesFields[imageFiled]);

        const imageData = await this._http
          .post<UserData>(this._api + "admin/upload-file", imageForm, {
            headers: getHeadersWithToken(),
          })
          .toPromise();

        imagesFields[imageFiled] = imageData[0].url;
      }

      this._http
        .patch(
          this._api + `admin/updateUserByAdmin/${newUser.id}`,
          {
            roleId: newUser.roleId,
            additionalData: {
              ...newUser.additionalData,
              ...imagesFields,
            },
          },
          {
            headers: getHeadersWithToken(),
          }
        )
        .subscribe();
    }

    return {
      ...newUser,
      additionalData: {
        ...newUser.additionalData,
        ...imagesFields,
      },
    };
  }

  // TODO: refactor this code with create new user
  async updateUser(userId: number, userData: Partial<UserData>) {
    const imagesFields: { [filed: string]: string } = {};

    for (const field in userData.additionalData) {
      if (userData.additionalData[field] instanceof File) {
        imagesFields[field] = userData.additionalData[field];
        delete userData.additionalData[field];
      }

      for (const imageFiled in imagesFields) {
        const imageForm = new FormData();
        imageForm.append("image", imagesFields[imageFiled]);

        const imageData = await this._http
          .post<UserData>(this._api + "admin/upload-file", imageForm, {
            headers: getHeadersWithToken(),
          })
          .toPromise();

        userData.additionalData[imageFiled] = imageData[0].url;
      }
    }

    return await this._http
      .patch(this._api + `admin/updateUserByAdmin/${userId}`, userData, {
        headers: getHeadersWithToken(),
      })
      .toPromise();
  }
}
