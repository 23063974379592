import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Filter } from "@kortobaa-front/k-ng-datatable";

import {
  Case,
  CaseSection,
  SavedCaseSection,
  CaseQuestion,
  QuestionType,
  SavedCaseQuestion,
  ReorderQuestionData,
} from "src/app/shared/models/interfaces";
import { DataService } from "src/app/shared/services/data.service";
import { getHeadersWithToken } from "src/app/shared/helpers/getHeadersWithToken";

@Injectable({
  providedIn: "root",
})
export class CasesService {
  private _caseServiceUrl = this._dataService._apiUrl + "admin/cases";
  private _caseSectionsServiceUrl =
    this._dataService._apiUrl + "admin/sections";
  private _caseQuestionsServiceUrl =
    this._dataService._apiUrl + "admin/questions";

  constructor(private _http: HttpClient, private _dataService: DataService) {}

  private readonly _options = {
    order: ["id desc"],
    where: { and: [{}] },
  };

  private urlWithId(url: string, id: number) {
    return `${url}/${id}`;
  }

  private _questionType: {
    [k: string]: QuestionType;
  };

  getCase(caseId: number) {
    return this._http.get<Case>(this.urlWithId(this._caseServiceUrl, caseId), {
      headers: getHeadersWithToken(),
    });
  }

  getCases(filter?: Filter) {
    let options = filter;
    if (!options) options = JSON.parse(JSON.stringify(this._options));

    // Hack: remove where filter if not exist to be compatible with mysql-connector 6
    if (Object.keys(options.where.and[0]).length === 0) delete options.where;

    return this._http.get<Case[]>(this._caseServiceUrl, {
      ...{
        headers: getHeadersWithToken(),
      },
      params: {
        filter: JSON.stringify(options),
        timestamp: new Date().getTime() as any,
      },
    });
  }

  addCase(caseData: FormData) {
    return this._http.post<Case>(this._caseServiceUrl, caseData, {
      headers: getHeadersWithToken(),
    });
  }

  updateCase(caseData: FormData, caseId: number) {
    return this._http.patch<Case>(
      this.urlWithId(this._caseServiceUrl, caseId),
      caseData,
      {
        headers: getHeadersWithToken(),
      }
    );
  }

  deleteCase(caseId: number) {
    return this._http.delete(this.urlWithId(this._caseServiceUrl, caseId), {
      headers: getHeadersWithToken(),
    });
  }

  deleteCaseSection(sectionId: number) {
    return this._http.delete(this.urlWithId(this._caseSectionsServiceUrl, sectionId), {
      headers: getHeadersWithToken(),
    });
  }

  getCaseSections(caseId: number) {
    const filter = JSON.stringify({
      where: { case_id: caseId },
    });
    return this._http.get<CaseSection[]>(`${this._caseSectionsServiceUrl}`, {
      headers: getHeadersWithToken(),
      params: {
        filter,
      },
    });
  }

  addCaseSection(sectionData: SavedCaseSection) {
    return this._http.post<CaseSection>(
      this._caseSectionsServiceUrl,
      sectionData,
      {
        headers: getHeadersWithToken(),
      }
    );
  }

  updateCaseSection(sectionData: Partial<CaseSection>, sectionId: number) {
    return this._http.patch<CaseSection>(
      this.urlWithId(this._caseSectionsServiceUrl, sectionId),
      sectionData,
      {
        headers: getHeadersWithToken(),
      }
    );
  }

  getCaseQuestions(sectionId?: number) {
    const filter = JSON.stringify({
      where: { section_id: sectionId },
      order: ["order ASC"],
    });
    return this._http.get<CaseQuestion[]>(`${this._caseQuestionsServiceUrl}`, {
      headers: getHeadersWithToken(),
      params: {
        filter,
      },
    });
  }

  async getQuestionTypes() {
    if (this._questionType) {
      return this._questionType;
    } else {
      this._questionType = await this._http
        .get<{ [k: string]: QuestionType }>(
          this._dataService._apiUrl + "user/questions/types-enum"
        )
        .toPromise();

      return this._questionType;
    }
  }

  addCaseQuestion(questionData: SavedCaseQuestion) {
    return this._http.post<CaseQuestion>(
      this._caseQuestionsServiceUrl,
      questionData,
      {
        headers: getHeadersWithToken(),
      }
    );
  }

  updateCaseQuestion(questionData: SavedCaseQuestion, questionId: number) {
    return this._http.patch<CaseSection>(
      this.urlWithId(this._caseQuestionsServiceUrl, questionId),
      questionData,
      {
        headers: getHeadersWithToken(),
      }
    );
  }

  deleteCaseQuestion(questionId: number) {
    return this._http.delete(
      this.urlWithId(this._caseQuestionsServiceUrl, questionId),
      {
        headers: getHeadersWithToken(),
      }
    );
  }

  reorderCaseQuestion(reorderQuestionData: ReorderQuestionData) {
    return this._http.patch(
      this._dataService._apiUrl + "admin/reorder-questions",
      reorderQuestionData,
      {
        headers: getHeadersWithToken(),
      }
    );
  }

  getDependQuestions(sectionId) {
    const filter = JSON.stringify({
      where: {
        and: [
          {
            type: "boolean",
          },
          {
            section_id: sectionId,
          },
        ],
      },
    });
    return this._http.get(`${this._dataService._apiUrl}/admin/questions`, {
      headers: getHeadersWithToken(),
      params: {
        filter,
      },
    });
  }
}
