export enum roles {
  admin = "admin",
  superAdmin = "superAdmin",
  user = "user",
}

export enum RolesId {
  superAdminRole = 1,
  adminRole = 2,
  user = 3,
  supervisor = 4,
  lawyer = 5,
  conciliator = 6,
  callCenter = 7,
}

export enum usersTypes {
  adminType = 1,
  userType = 2,
  deliveryType = 3,
}

export enum UserRoles {
  user = "user",
  lawyer = "lawyer",
  conciliator = "conciliator",
  supervisor = "supervisor",
  callCenter = "callCenter",
}

export interface SavedCase {
  title: string;
  status: boolean;
  image_url: string;
  hasReconciliation: boolean;
  order: number;
}

export interface Case {
  id: number;
  title: string;
  status: boolean;
  image_url: string;
  hasReconciliation: boolean;
  created_at: Date;
  order: number;
  updated_at: Date;
}

export interface SavedCaseSection {
  title: string;
  case_id: number;
  order: number;
  status: boolean;
}

export interface CaseSection {
  id: number;
  title: string;
  slug: string;
  order: number;
  status: boolean;
  created_at: Date;
  updated_at: Date;
  case_id: number;
}

export enum QuestionType {
  "TEXT" = "string",
  "NUMBER" = "number",
  "BOOLEAN" = "boolean",
  "CHECK" = "check",
  "RADIO" = "radio",
  "LIST" = "list",
}

export interface SavedCaseQuestion {
  title: string;
  order: number;
  type: QuestionType;
  list?: any[]; // string[]
  isRequired: boolean;
  status: boolean;
  section_id: number;
}

export interface CaseQuestion {
  id: number;
  title: string;
  order: number;
  type: QuestionType;
  list?: any[]; // CaseQuestionAnswer[]
  isRequired: boolean;
  slug: string;
  status: boolean;
  section_id: number;
  created_at: Date;
  updated_at: Date;
}

export interface CaseQuestionAnswer {
  value: string;
  slug: string;
}

export interface ReorderQuestionData {
  questionId: number;
  currentOrder: number;
  previousOrder: number;
  sectionId: number;
}

export interface MenuData {
  title: string;
  url: string;
  permission?: string;
  icon?: string;
  iconImageName?: string;
  child?: MenuData[];
}

export enum SavedUserData {
  "ALL" = "ALL",
  "PERSONAL_DATA" = "PERSONAL_DATA",
  "IDENTITY_DATA" = "IDENTITY_DATA",
  "PROFESSIONAL_DATA" = "PROFESSIONAL_DATA",
  "LAWYER_DATA" = "LAWYER_DATA",
  "CONTACT_DATA" = "CONTACT_DATA",
  "OUR_CUSTOMER_DATA" = "OUR_CUSTOMER_DATA",
}

export interface UserData {
  id: number;
  username: string;
  email: string;
  nid: string;
  phone: string;
  additionalData: {
    [property: string]: any;
  };
  isDisabled: number;
  banned: number;
  roleId: number;
  userCasesCount?: number;
  verified?: boolean | number;
}

export enum UserInputMode {
  EDIT = "EDIT",
  VIEW = "VIEW",
}

export enum UserInputType {
  TEXT = "TEXT",
  PHONE = "PHONE",
  TEXT_AREA = "TEXT_AREA",
  DATE = "DATE",
  IMAGE = "IMAGE",
  BOOLEAN = "BOOLEAN",
  BOOLEAN_REVERSE = "BOOLEAN_REVERSE",
  RADIO = "RADIO",
  CHECK = "CHECK",
  MULTIPLE_TEXT = "MULTIPLE_TEXT",
}

export interface Service {
  title: string;
  description: string;
  file: File;
  enabled: boolean;
  nextTwoCasesDiscountPercent?: number;
  type: string;
  discount?: boolean;
}

export interface ServiceList {
  id: number;
  title: string;
  slug: string;
  description: string;
  imageUrl: string;
  enabled: boolean;
  nextTwoCasesDiscountPercent?: number;
  type: string;
  protected: boolean;
  hasPayments: boolean;
  created_at: string;
  updated_at?: string;
  plans?: any[];
}

export interface PlanFeatures {
  id?: number;
  title: string;
  price: number;
  preFeesAmont?: number;
  enabled: boolean;
  endsIn?: number;
  endsInUnit?: string;
  availabilityDaysFrom?: string;
  availabilityDaysTo?: string;
  availabilityTimeFrom?: string;
  availabilityTimeTo?: string;
  serviceId: number;
  planFeatures?: PlanFeaturesEntity[];
}
export interface PlanFeaturesEntity {
  mainTitle?: string;
  title: string;
  included?: boolean | null;
  type: string;
  icon?: string;
  plans?: any[];
}

export interface PlanFeaturesRes {
  id: number;
  title: string;
  price: number;
  preFeesAmont: number;
  enabled: boolean;
  endsIn: number;
  endsInUnit: string;
  hasPayments: boolean;
  availabilityDaysFrom: string;
  availabilityDaysTo: string;
  availabilityTimeFrom: string;
  availabilityTimeTo: string;
  created_at: string;
  updated_at?: null;
  serviceId: number;
  planFeatures?: null[] | null;
}

export interface PromoCode {
  title: string;
  discountPercentage: number;
  expired_at: string;
  id?: number;
  created_at?: string;
}
