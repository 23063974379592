import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { AddPromoCodeDialogComponent } from '../../dialogs/add-promo-code-dialog/add-promo-code-dialog.component';

@Component({
  selector: 'app-promo-code-page',
  templateUrl: './promo-code-page.component.html',
  styleUrls: ['./promo-code-page.component.scss']
})
export class PromoCodePageComponent implements OnInit {
  current: string = 'active-code'
  constructor(
    private _dialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  // handle tab changed
  tabChanged(tabChangeEvent: MatTabChangeEvent) {
    tabChangeEvent.index == 0 ? this.current = 'active-code' : this.current = 'expire-code'
  }

  // open add new promo code
  async openAddPromoCodeDialog() {
    this._dialog
      .open(AddPromoCodeDialogComponent, {
        direction: "rtl",
        panelClass: "save-case-dialog",
        data: {},
      })
  }
}
