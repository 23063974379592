import { AbstractControl } from "@angular/forms";

export function addDuplicatedError(control: AbstractControl) {
  control.setErrors({ duplicate: true });
}

export function removeDuplicatedErrorOnUpdate(control: AbstractControl) {
  return control?.valueChanges.subscribe(() => {
    if (control.hasError("duplicate")) {
      delete control.errors["duplicate"];
      control.updateValueAndValidity();
    }
  });
}
