import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "delete-confirm-dialog",
  templateUrl: "./delete-confirm-dialog.component.html",
  styleUrls: ["./delete-confirm-dialog.component.scss"],
})
export class DeleteConfirmDialog {
  constructor(
    public _dialogRef: MatDialogRef<DeleteConfirmDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this._dialogRef.close();
  }

  accept(boolean: boolean = true) {
    this._dialogRef.close(boolean);
  }
}
