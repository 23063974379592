import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { getHeadersWithToken } from "src/app/shared/helpers/getHeadersWithToken";
import { environment } from "src/environments/environment";
import { PlanFeatures, PlanFeaturesRes } from "src/app/shared/models/interfaces";

@Injectable({
  providedIn: "root",
})
export class PlansService {
  private readonly _api = environment.apiHost;

  constructor(private _http: HttpClient) { }

  // add new plan
  addNewPlan(planData: PlanFeatures) {
    return this._http.post<PlanFeaturesRes>(this._api + `admin/plans`, planData, {
      headers: getHeadersWithToken(),
    });
  }

  // delete plan
  deletePlan(id: number) {
    return this._http.delete(this._api + `admin/plans/${id}`, {
      headers: getHeadersWithToken(),
    });
  }

  // edit plan by specific Id
  editPlan(serviceData: PlanFeatures, id: number) {
    return this._http.patch<PlanFeaturesRes>(
      this._api + `admin/plans/${id}`,
      serviceData,
      {
        headers: getHeadersWithToken(),
      }
    );
  }
}
