import { Component, OnInit } from "@angular/core";
import { TableService } from "@kortobaa-front/k-ng-datatable";
import { ActivatedRoute } from "@angular/router";
import { first } from "rxjs/operators";
import { DataService } from "src/app/shared/services/data.service";
import { AngularEditorConfig } from "@kolkov/angular-editor";

@Component({
  selector: "app-pages-form",
  templateUrl: "./pages-form.component.html",
  styleUrls: ["./pages-form.component.scss"],
})
export class PagesFormComponent implements OnInit {
  url: string = "pages";
  new: boolean = true;
  loading = true;
  schema: any = {
    id: 0,
    url: "",
    content: "",
    status: 1,
  };
  data: any = {
    id: 0,
    url: "",
    content: "",
    status: 1,
  };
  pages = [];
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "500px",
    minHeight: "500px",
    maxHeight: "auto",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "Enter text here...",
    defaultParagraphSeparator: "",
    defaultFontName: "",
    defaultFontSize: "",
    /*fonts: [
      {class: 'arial', name: 'Arial'},
      {class: 'times-new-roman', name: 'Times New Roman'},
      {class: 'calibri', name: 'Calibri'},
      {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],*/
    //uploadUrl: 'v1/image',
    //upload: (file: File) => { ... }
    //uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: "top",
    toolbarHiddenButtons: [["insertImage"]],
  };

  constructor(
    private tableService: TableService,
    private route: ActivatedRoute,
    public dataService: DataService
  ) {}

  async ngOnInit() {
    let data = this.route.snapshot.paramMap.get("id");
    if (data) {
      this.new = false;
      await this.getData(data);
    }
    this.tableService.tableUrl = "pages";
  }

  async getData(data) {
    //console.log(data);
    this.tableService.tableUrl = `page/${data}`;
    const getData: any = await this.tableService
      .getData()
      .pipe(first())
      .toPromise();
    this.data = getData;
    this.loading = false;
  }

  saveData() {
    if (this.validateData()) {
      this.tableService.SaveItem(this.data).subscribe(
        (row: any) => {
          this.dataService.notification("Saved Successfully.");
          this.tableService.redirect(this.url);
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          alert(error.error.error.message);
        }
      );
    }
  }

  validateData(): boolean {
    //console.log(this.mosqueData);
    //Object.entries(this.schema).forEach(([schemaKey, schemaValue]: any) => {
    let returnBoolean: boolean = true;
    const schemaKeys = Object.keys(this.schema);
    const schemaVlues = Object.values(this.schema);
    const mosqueData = this.data;
    Object.entries(mosqueData).forEach(([key, value]: any, index) => {
      //console.log(key,schemaKeys[index])
      const schemaIndex = schemaKeys.findIndex((data) => data == key);
      if (schemaIndex != -1) {
        if (typeof value != typeof schemaVlues[schemaIndex]) {
          //alert(`${key} must be ${schemaVlues[schemaIndex]}.`);
          this.dataService.notification(
            `${key} must be ${schemaVlues[schemaIndex]}.`
          );
        } else {
          //console.log(value,schemaKeys[schemaIndex])
          if (value.length == 0) {
            //alert(`${key} is required.`);
            this.dataService.notification(`${key} is required.`);
            returnBoolean = false;
          }
        }
      } else {
        delete mosqueData[key];
      }
    });
    //})
    return returnBoolean;
  }
}
