import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "src/app/core/auth.service";
import { Router } from "@angular/router";
import { DataService } from "src/app/shared/services/data.service";
import { TranslateService } from "@ngx-translate/core";
import { roles } from "src/app/shared/models/interfaces";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MenuService {
  menuData$ = new BehaviorSubject(null);
  menuData = [
    {
      title: "Home",
      url: "/home",
      icon: "home",
      permission: "home",
      child: false,
    },
    {
      title: "Donates",
      url: "",
      icon: "account_balance_wallet",
      permission: "",
      child: [
        {
          title: "Pending",
          url: "/donates/pending",
          icon: false,
          permission: "Donates",
          child: false,
        },
        {
          title: "Processing",
          url: "/donates/processing",
          icon: false,
          permission: "Donates",
          child: false,
        },
        {
          title: "Shipped",
          url: "/donates/shipped",
          icon: false,
          permission: "Donates",
          child: false,
        },
        {
          title: "Delivered",
          url: "/donates/delivered",
          icon: false,
          permission: "Donates",
          child: false,
        },
        {
          title: "Canceled",
          url: "/donates/canceled",
          icon: false,
          permission: "Donates",
          child: false,
        },
      ],
    },
    /*{
          title:'Barid',
          url:'',
          icon:'account_balance_wallet',
          permission:'',
          child:[
            {
              title:'Pending',
              url:'/barid/pending',
              icon:false,
              permission:'Donates',
              child:false,
            },
            {
              title:'Processing',
              url:'/barid/processing',
              icon:false,
              permission:'Donates',
              child:false,
            },
            {
              title:'Shipped',
              url:'/barid/shipped',
              icon:false,
              permission:'Donates',
              child:false,
            },
            {
              title:'Delivered',
              url:'/barid/delivered',
              icon:false,
              permission:'Donates',
              child:false,
            },
            {
              title:'Canceled',
              url:'/barid/canceled',
              icon:false,
              permission:'Donates',
              child:false,
            },
          ],
        },*/
    {
      title: "Mosques",
      url: "/mosques",
      icon: "line_weight",
      permission: "Mosques",
      child: false,
    },
    {
      title: "Graves",
      url: "/graves",
      icon: "line_weight",
      permission: "Graves",
      child: false,
    },
    {
      title: "My Places",
      url: "/myPlaces",
      icon: "line_weight",
      permission: "myPlaces",
      child: false,
    },
    {
      title: "Users",
      url: "/users",
      icon: "group",
      permission: "Users",
      child: false,
    },
    {
      title: "FAQ",
      url: "/faq",
      icon: "question_answer",
      permission: "FAQ",
      child: false,
    },
    {
      title: "Messages",
      url: "/messages",
      icon: "mail",
      permission: "Messages",
      child: false,
    },
    {
      title: "Settings",
      url: "",
      icon: "build",
      permission: "",
      child: [
        {
          title: "Info",
          url: "/Info",
          icon: false,
          permission: "Info",
          child: false,
        },
        {
          title: "Bottles Companies",
          url: "/bottelsCompany",
          icon: false,
          permission: "bottelsCompany",
          child: false,
        },
        {
          title: "Bottle Sizes",
          url: "/bottle_sizes",
          icon: false,
          permission: "Bottle_Sizes",
          child: false,
        },
        {
          title: "Governates",
          url: "/gov",
          icon: false,
          permission: "Gov",
          child: false,
        },
        {
          title: "Areas",
          url: "/area",
          icon: false,
          permission: "Area",
          child: false,
        },
        {
          title: "Roles",
          url: "/roles",
          icon: false,
          permission: "Roles",
          child: false,
        },
      ],
    },
    {
      title: "Reports",
      url: "",
      icon: "description",
      permission: "",
      child: [
        {
          title: "Devices",
          url: "/devices",
          icon: false,
          permission: "Devices",
          child: false,
        },
        {
          title: "Payments",
          url: "/payments",
          icon: false,
          permission: "Payments",
          child: false,
        },
      ],
    },
  ];
  emptyArrayForCheck: string[] = [];
  finalMenu = [];

  constructor(
    public auth: AuthService,
    private router: Router,
    private dataService: DataService,
    private translate: TranslateService
  ) {
    if (this.dataService.currentUser) {
      this.updateMenuData();
    }
  }

  updateMenuData() {
    //check if user has admin role
    if (
      this.dataService.currentUser &&
      (this.dataService.currentUser.roles.includes(roles.admin) ||
        this.dataService.currentUser.roles.includes(roles.superAdmin))
    ) {
      //lets give finalPermission menuData items
      this.finalMenu = this.menuData;
      //and now lets get menu without company menus
      const companyMenus = this.finalMenu;
      this.menuData$.next(companyMenus);
    } else {
      //lets get permissions menu
      this.getMenuFromPermissions();
    }
    /*this.readTranlationFromMenu();
    this.translate.onLangChange.subscribe(data => {
      this.translate.get(this.emptyArrayForCheck)
      .subscribe(translations => {
        //console.log(this.emptyArrayForCheck)
        let childIndexCounter:number = 0;
        let parentIndex:number = -1;
        this.emptyArrayForCheck.forEach((data,index) => {
          const checkIfHasChild = data.split('.');
          if(checkIfHasChild.length > 2){
            this.menuData[parentIndex].child[childIndexCounter].title = translations[data];
            childIndexCounter += 1;
          }else{
            parentIndex += 1;
            childIndexCounter = 0;
            this.menuData[parentIndex].title = translations[data];
          }
        });
      });
    })*/
  }

  getMenuFromPermissions() {
    const permissions: string[] = this.dataService.currentUser.roles;
    //lets push home permission as default permission
    permissions.push("home");
    //final menu to set it as menuData
    this.finalMenu = [];
    this.menuData.forEach((menuItem: any) => {
      //if this menu is header of subMenus
      if (menuItem.child) {
        //lets loop on it to check if it in permissions
        menuItem.child.forEach((subMenuItem) => {
          let findMenuinPermissions = permissions.find(
            (menuData: any) =>
              menuData.toLowerCase() == subMenuItem.permission.toLowerCase()
          );
          //if menuData found in permissions
          if (findMenuinPermissions) {
            //check if parent menu is already in finalMenu
            let checkIfParentMenuInFinalMenu = this.finalMenu.findIndex(
              (final) =>
                final.title.toLowerCase() == menuItem.title.toLowerCase()
            );
            //if parent is not exist
            if (checkIfParentMenuInFinalMenu == -1) {
              //create new Menu with parent props but change child to first child
              let newMenu = {
                title: menuItem.title,
                url: menuItem.url,
                icon: menuItem.icon,
                permission: menuItem.permission,
                child: [subMenuItem], //set first child
              };
              this.finalMenu.push(newMenu);
            } else {
              //if parent already exist lets push submenu in parent child
              this.finalMenu[checkIfParentMenuInFinalMenu].child.push(
                subMenuItem
              );
            }
          }
        });
      } else {
        //lets check if this menu in permissins
        let findMenuinPermissions = permissions.find(
          (menuData: any) =>
            menuData.toLowerCase() == menuItem.permission.toLowerCase()
        );
        //if yes lets put it in finalMenu array
        if (findMenuinPermissions) {
          this.finalMenu.push(menuItem);
        }
      }
    });
    //console.log(finalMenu)
    //this.menuData = finalMenu;
    this.menuData$.next(this.finalMenu);
  }

  readTranlationFromMenu() {
    this.menuData.forEach((data: any, index) => {
      let translate = "sideMenu.";
      if (data.child != false) {
        translate += data.title;
        this.emptyArrayForCheck.push(translate + "Menu");
        translate += ".";
        data.child.forEach((element) => {
          const childTitle = translate + element.title;
          this.emptyArrayForCheck.push(childTitle);
        });
      } else {
        translate += data.title;
        this.emptyArrayForCheck.push(translate);
      }
    });
  }
}
