import { Directive, OnInit, OnDestroy, ElementRef } from "@angular/core";
import { Subject } from "rxjs";
import { DataService } from "../services/data.service";

@Directive({
  selector: "[theme]",
})
export class ThemeDirective implements OnInit, OnDestroy {
  private _destroy$ = new Subject();

  primaryColor;
  secondaryColor;
  tertiaryColor;

  constructor(
    private _elementRef: ElementRef,
    private dataService: DataService
  ) {}

  ngOnInit() {
    /*this.dataService.primaryColor.subscribe(color => {this.primaryColor = color; this.updateTheme();});
    this.dataService.secondaryColor.subscribe(color => {this.secondaryColor = color; this.updateTheme();});
    this.dataService.tertiaryColor.subscribe(color => {this.tertiaryColor = color; this.updateTheme();});*/
  }

  ngOnDestroy() {
    /*this._destroy$.next();
    this._destroy$.complete();*/
  }

  updateTheme() {
    /*this._elementRef.nativeElement.style.setProperty('--primary-color', this.primaryColor);
    this._elementRef.nativeElement.style.setProperty('--secondary-color', this.secondaryColor);
    this._elementRef.nativeElement.style.setProperty('--tertiary-color', this.tertiaryColor);*/
  }
}
