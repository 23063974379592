import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NgForm } from "@angular/forms";
import { ServicesService } from "../../services/services.service";

@Component({
  selector: "app-upgrade-service-dialog",
  templateUrl: "./upgrade-service-dialog.component.html",
  styleUrls: ["./upgrade-service-dialog.component.scss"],
})
export class UpgradeServiceDialogComponent {
  isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<UpgradeServiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _servicesService: ServicesService,
  ) { }

  async upgradeService(upgradeServiceForm: NgForm) {
    this.isLoading = true;
    try {
      await this._servicesService
        .updateUpgradeService(+upgradeServiceForm.form.value.amount)
        .toPromise();
      this.closeDialog(true);
    } catch (err) {
      // console.error(err);
    }
    this.isLoading = false;
  }

  closeDialog(data?: boolean) {
    this.dialogRef.close(data);
  }
}
