import { Component, OnInit, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";

import {
  TableSchema,
  TableTypes,
  DataTableComponentBase,
  DataTableComponent,
  TableService,
  Filter,
} from "@kortobaa-front/k-ng-datatable";

import { AuthService } from "src/app/core/auth.service";
import { DataService } from "src/app/shared/services/data.service";
import { ConfirmDialogComponent } from "src/app/shared/components/confirm-dialog/confirm-dialog.component";
import { getHeadersWithToken } from "src/app/shared/helpers/getHeadersWithToken";
import { environment } from "src/environments/environment";
import * as moment from "moment-timezone";
import { isIsoDate } from "src/app/shared/helpers/date.helper";
import { ViewEvaluationDialogComponent } from "../view-evaluation-dialog/view-evaluation-dialog.component";

@Component({
  selector: 'app-evaluations-inquiries-report',
  templateUrl: './evaluations-inquiries-report.component.html',
  styleUrls: ['./evaluations-inquiries-report.component.scss']
})
export class EvaluationsInquiriesReportComponent extends DataTableComponentBase implements OnInit {
  moduleName = "reports";
  url = "evaluations-inquiries-reports";
  filters: Filter = {
    where: {
      and: [
        {
          created_at: {
            gt: new Date(
              new Date().setDate(new Date().getDate() - 7)
            ).toISOString(),
          },
        },
        { created_at: { lt: new Date(Date.now()).toISOString() } },
      ],
    },
  };

  displayedColumns: TableSchema[] = [
    {
      title: "inquiry-num",
      id: "application_no",
      type: TableTypes.text,
      editable: false,
      notOnFilter: true,
    },
    {
      title: "inquiry-type",
      id: "title",
      type: TableTypes.text,
      editable: false,
      notOnFilter: false,
    },
    {
      title: "user-name",
      id: "user_name",
      type: TableTypes.text,
      editable: false,
      notOnFilter: true,
    },
    {
      title: "supervisor-username",
      id: "supervisor_name",
      type: TableTypes.text,
      editable: false,
      notOnFilter: true,
    },
    {
      title: "lawyer-username",
      id: "assignee_name",
      type: TableTypes.text,
      editable: false,
      notOnFilter: true,
    },
    {
      title: "evaluation-date",
      id: "created_at",
      type: TableTypes.text,
      editable: false,
      notOnFilter: true,
    },
    {
      title: "evaluation-actions",
      id: "_",
      type: TableTypes.select,
      isActionsList: true,
      list: [
        { title: "chooses-action", value: "_" },
        { title: "view-evaluation", value: "view-evaluation" },

      ],
      editable: false,
      notOnFilter: true,
    },
  ];
  dataSource;
  isDataLoading = false;

  @ViewChild(DataTableComponent)
  private _dataTableComponent: DataTableComponent;

  constructor(
    public authService: AuthService,
    private _tableService: TableService,
    private _dataService: DataService,
    private _dialog: MatDialog,
    private _http: HttpClient,
    private _translate: TranslateService
  ) {
    super();
    this._tableService.tableUrl = "admin/inquiry-evaluations";
  }

  async ngOnInit() { }

  async getData(filters?: Filter) {
    filters = { ...this._dataTableComponent.backendFilter, where: this.filters.where }
    this.isDataLoading = true;
    try {
      this._tableService.tableUrl = "admin/inquiry-evaluations";
      let data = await this._tableService.getData<any>(filters).toPromise();
      this.dataSource = data.map((elem) => {
        const isDate = isIsoDate(elem["created_at"]);
        const isNullish = elem["created_at"] == null;
        if (isNullish) elem["created_at"] = "غير متاح";
        if (isDate) {
          moment.locale("ar");
          elem["created_at"] = moment(new Date(elem["created_at"])).format(
            "LL"
          );
        }
        return {
          ...elem,
        };
      });
    } catch (err) {
      // console.error(err);
    }

    this.isDataLoading = false;
  }

  async getExportData(data) {
    try {
      this._tableService.tableUrl = "admin/inquiry-evaluations";
      let data = await this._tableService.getData<any>().toPromise();
      this.dataSource = data.map((elem) => {

        const isDate = isIsoDate(elem["created_at"]);
        const isNullish = elem["created_at"] == null;
        if (isNullish) elem["created_at"] = "غير متاح";
        if (isDate) {
          moment.locale("ar");
          elem["created_at"] = moment(new Date(elem["created_at"])).format(
            "LL"
          );
        }
        return {
          ...elem,
        };
      });
      await this._dataTableComponent.downloadAllData(data);
    } catch (err) {
      // console.error(err);
    }
  }

  isIsoDate(arg0: any) {
    throw new Error("Method not implemented.");
  }

  getDataForAction(data) {
    this._tableService.SaveItem(data).subscribe(
      (row: any) => {
        this._tableService.redirect(this.url);
      },
      (error) => {
        this._dataService.notification(error.error.error.message);
      }
    );
  }

  async handleRowActions(data: { action: string; row: any }) {
    const { action, row } = data;
    const evaluationId = row.id;

    if (action == "view-evaluation") {
      await this.handleViewEvaluationDialog(evaluationId);
    }
  }

  async handleViewEvaluationDialog(evaluationId: Number) {
    const dialogData: null | { assigneeIds: number[] } = await this._dialog
      .open(ViewEvaluationDialogComponent, {
        direction: this._translate.currentLang == "ar" ? "rtl" : "ltr",
        panelClass: "white-dialog",
        width: "80rem",
        minHeight: "40rem",
        maxWidth: "90vw",
        autoFocus: false,
        data: {
          evaluationId,
          status: 'inquiry'
        },
      })
      .afterClosed()
      .toPromise();
  }

  async handleSearchChange(value: string) {
    // TODO: refactor all this logic, it's just a workaround to manipulate the data table component directly because of the poor API
    this.isDataLoading = true;
    console.log(value);

    const searchResult = await this._http
      .post<{ count: number; result: any[] }>(
        `${environment.apiHost}admin/usercase-search`,
        {
          keyword: value,
          skip: 0,
        },
        {
          headers: getHeadersWithToken(),
        }
      )
      .toPromise();

    // console.log(searchResult);

    this.dataSource = searchResult.result.map((userCase) => ({
      ...userCase,
      supervisorName: `المشرف: ${userCase.userSupervisor?.username}`,
      caseImage: userCase.caseType.image_url,
    }));

    // TODO: not complete solution because pagination not works
    this._dataTableComponent.tableDataLength = searchResult.count;

    this.isDataLoading = true;
  }

  async filterByDate(value: any) {
    let startDate = moment(value.startDate).add(3, "h").toDate()?.toISOString();
    let endDate = moment(value.endDate).add(3, "h").toDate()?.toISOString();
    this.filters["where"] = {
      and: [{ created_at: { gt: startDate } }, { created_at: { lt: endDate } }],
    };
    this.getData(this.filters);
  }
}
