import { Component, ViewChild, AfterViewInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";

import { CasesService } from "src/app/cms/services/cases.service";
import { ConfirmDialogComponent } from "src/app/shared/components/confirm-dialog/confirm-dialog.component";
import { SaveCaseDialogComponent } from "src/app/cms/cases-types/save-case-dialog/save-case-dialog.component";
import { Case } from "src/app/shared/models/interfaces";
import {
  DataTableFilterComponent,
  Filter,
  FilterDialogComponent,
  TableSchema,
  TableTypes,
  WhereFilter,
} from "@kortobaa-front/k-ng-datatable";

@Component({
  selector: "app-cases-types",
  templateUrl: "./cases-types.component.html",
  styleUrls: ["./cases-types.component.scss"],
})
export class CasesTypesComponent implements AfterViewInit {
  caseList: Case[] = [];
  isLoading = false;
  backendFilter = null;
  @ViewChild(DataTableFilterComponent)
  filterComponent: DataTableFilterComponent;

  displayedColumns: TableSchema[] = [
    {
      title: "case-type-name",
      id: "title",
      type: TableTypes.text,
    },
    {
      title: "case-status",
      id: "status",
      type: TableTypes.boolean,
    },
    {
      title: "case-hasReconciliation",
      id: "hasReconciliation",
      type: TableTypes.boolean,
    },
  ];

  constructor(
    public translate: TranslateService,
    private _dialog: MatDialog,
    private _casesService: CasesService
  ) { }

  ngAfterViewInit() {
    this.backendFilter = {
      where: {
        and: [this.filterComponent.filters],
      },
    };
    this.getCaseList();
  }

  async getCaseList(filters?: Filter) {
    this.isLoading = true;
    try {
      this.caseList = await this._casesService.getCases(filters).toPromise();
    } catch (err) {
      // console.error(err);
    }
    this.isLoading = false;
  }

  openAddCaseDialog() {
    this._dialog
      .open(SaveCaseDialogComponent, {
        direction: this.translate.currentLang == "ar" ? "rtl" : "ltr",
        panelClass: "save-case-dialog",
        data: {
          title: this.translate.instant("save-case-dialog.add-case"),
        },
      })
      .afterClosed()
      .subscribe((newCase: Case) => {
        if (newCase) this.caseList.push(newCase);
      });
  }

  openFilterCaseDialog() {
    this._dialog
      .open(FilterDialogComponent, {
        maxWidth: "60vw",
        direction: this.translate.currentLang == "ar" ? "rtl" : "ltr",
        panelClass: "save-case-dialog",
        data: {
          displayData: this.displayedColumns,
        },
      })
      .afterClosed()
      .subscribe((filter: WhereFilter) => {
        if (filter) {
          this.filterComponent.addFilter(filter);
        }
      });
  }

  async emitGetDataEvent() {
    this.getCaseList(this.backendFilter);
  }

  openEditCaseDialog(caseData: Case) {
    this._dialog
      .open(SaveCaseDialogComponent, {
        direction: this.translate.currentLang == "ar" ? "rtl" : "ltr",
        panelClass: "save-case-dialog",
        data: {
          title: this.translate.instant("save-case-dialog.modify-case"),
          caseData,
        },
      })
      .afterClosed()
      .subscribe((updatedCase: Case) => {
        if (updatedCase) {
          const updatedCaseIndex = this.caseList.findIndex(
            (caseItem) => caseItem.id == updatedCase.id
          );
          this.caseList[updatedCaseIndex] = updatedCase;
        }
      });
  }

  openDeleteCaseDialog(caseId: number) {
    this._dialog
      .open(ConfirmDialogComponent, {
        direction: this.translate.currentLang == "ar" ? "rtl" : "ltr",
        panelClass: "confirm-dialog",
        data: {
          content: this.translate.instant(
            "confirm-dialog.are-you-want-delete-case"
          ),
          icon: "delete_forever",
        },
      })
      .afterClosed()
      .subscribe(async (isDeleted) => {
        if (isDeleted) {
          try {
            await this._casesService.deleteCase(caseId).toPromise();

            this.caseList = this.caseList.filter(
              (caseItem) => caseItem.id != caseId
            );
          } catch (err) {
            // console.error(err);
          }
        }
      });
  }
}
