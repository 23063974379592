import { Component, ViewChild } from "@angular/core";
import {
  DataTableComponentBase,
  Filter,
  TableSchema,
  TableService,
  TableTypes,
} from "@kortobaa-front/k-ng-datatable";
import { AuthService } from "src/app/core/auth.service";
import { DataService } from "src/app/shared/services/data.service";
import { DataTableComponent } from "@kortobaa-front/k-ng-datatable";
import * as moment from "moment-timezone";
import { MatDialog } from "@angular/material/dialog";
import { ReactivatePromoCodeDialogComponent } from "../../dialogs/reactivate-promo-code-dialog/reactivate-promo-code-dialog.component";

@Component({
  selector: 'app-expire-promo-code',
  templateUrl: './expire-promo-code.component.html',
  styleUrls: ['./expire-promo-code.component.scss']
})
export class ExpirePromoCodeComponent extends DataTableComponentBase {
  moduleName = "coupons";
  url = "coupons";
  dataSource;
  isDataLoading = false;
  displayedColumns: TableSchema[] = [
    {
      title: "code-name",
      id: "title",
      type: TableTypes.text,
      editable: false,
    },
    {
      title: "discount-percentage",
      id: "discount",
      type: TableTypes.number,
      editable: false,
    },
    {
      title: "expire-date",
      id: "expired",
      type: TableTypes.text,
      editable: false,
    },
    {
      title: `re-active`,
      id: "test",
      type: TableTypes.extrnalinfo,
      notOnFilter: true,
    }
  ];

  @ViewChild(DataTableComponent)
  private _dataTableComponent: DataTableComponent;

  constructor(
    public authService: AuthService,
    private _tableService: TableService,
    private _dataService: DataService,
    private _dialog: MatDialog,
  ) {
    super();
    this._tableService.tableUrl = "admin/coupons";
  }
  filters: Filter = {
    where: {
      and: [
        { expired_at: { lt: new Date(Date.now()).toISOString() } },
      ],
    },
  };
  async getData(filters?: Filter) {
    filters["where"] = this.filters.where;
    this.isDataLoading = true;
    try {
      let data = await this._tableService.getData<any>(filters).toPromise();
      this.dataSource = data.map((elem) => {
        elem["expired"] = moment(new Date(elem["expired_at"])).format("LL");
        elem["discount"] = `${elem["discountPercentage"]} % `;
        return elem;
      });
    } catch (err) {
      // console.error(err);
    }
    this.isDataLoading = false;
  }

  getDataForAction(data) {
    this._tableService.SaveItem(data).subscribe(
      (row: any) => {
        this._tableService.redirect(this.url);
      },
      (error) => {
        this._dataService.notification(error.error.error.message);
      }
    );
  }

  isIsoDate(arg0: any) {
    throw new Error("Method not implemented.");
  }

  // show consultant data by redirect
  async handleActionOnPromoCode(data: any) {
    this._dialog
      .open(ReactivatePromoCodeDialogComponent, {
        direction: "rtl",
        panelClass: "save-case-dialog",
        data: {
          promoCodeData: data.data
        },
      })
  }

}
