import { NgModule } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTabsModule } from "@angular/material/tabs";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatTableModule } from "@angular/material/table";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatDividerModule } from "@angular/material/divider";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatCheckboxModule } from "@angular/material/checkbox";
import {MatTooltipModule} from '@angular/material/tooltip';
import { MatChipsModule } from "@angular/material/chips";
import { TranslateModule } from "@ngx-translate/core";

import { SharedModule } from "../shared/shared.module";
import {
  UserListComponent,
  DialogOverviewExampleDialog,
} from "./user-list/user-list.component";
import { SaveUserComponent } from "./save-user/save-user.component";
import { UserDataInputComponent } from "./user-data-input/user-data-input.component";
import { SaveUserHeaderComponent } from "./save-user-header/save-user-header.component";


@NgModule({
  imports: [
    SharedModule,
    MatDialogModule,
    MatTabsModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatInputModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatDividerModule,
    MatRadioModule,
    MatCheckboxModule,
    MatChipsModule,
    TranslateModule,
    MatTooltipModule
  ],
  declarations: [
    UserListComponent,
    DialogOverviewExampleDialog,
    SaveUserComponent,
    UserDataInputComponent,
    SaveUserHeaderComponent,
  ],
  exports: [UserListComponent],
  entryComponents: [DialogOverviewExampleDialog],
})
export class UsersModule {}
