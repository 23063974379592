import {
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
  ViewContainerRef,
} from "@angular/core";
import { ThemePalette } from "@angular/material/core";
import { MatButton } from "@angular/material/button";
import { MatProgressSpinner } from "@angular/material/progress-spinner";

function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

@Directive({
  selector: `[fullLoading]`,
})
export class MatPageLoadingDirective implements OnChanges {
  private spinnerFactory: ComponentFactory<MatProgressSpinner>;
  private spinner: ComponentRef<MatProgressSpinner>;

  @Input()
  fullLoading: boolean;

  @Input()
  disabled: boolean;

  @Input()
  color: ThemePalette;

  uid: string;

  constructor(
    //private matButton: MatButton,
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef,
    private renderer: Renderer2,
    private targetEl: ElementRef
  ) {
    this.spinnerFactory =
      this.componentFactoryResolver.resolveComponentFactory(MatProgressSpinner);
  }

  /*ngOnInit(){
        //
    }*/

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fullLoading.firstChange) {
      //change parent postiotin to relative
      const oldPosition = window.getComputedStyle(
        this.targetEl.nativeElement
      ).position;
      //console.log(window.getComputedStyle(this.targetEl.nativeElement))
      if (
        oldPosition != "absolute" &&
        oldPosition != "fixed" &&
        oldPosition != "relative"
      )
        this.targetEl.nativeElement.style.position = "relative";
      this.uid = "loading-container-" + uuidv4();
      const loadingContainer = this.renderer.createElement("div");
      this.renderer.setStyle(
        loadingContainer,
        "display",
        this.fullLoading ? "flex" : "none"
      );
      this.renderer.setStyle(loadingContainer, "justify-content", "center");
      this.renderer.setStyle(loadingContainer, "align-items", "center");
      this.renderer.addClass(loadingContainer, this.uid);
      this.renderer.setStyle(loadingContainer, "position", "absolute");
      this.renderer.setStyle(loadingContainer, "top", "0");
      this.renderer.setStyle(loadingContainer, "background", "#e4e4e4");
      this.renderer.setStyle(loadingContainer, "width", "100%");
      this.renderer.setStyle(loadingContainer, "height", "100%");
      this.renderer.setStyle(
        loadingContainer,
        "box-shadow",
        "0px 2px 8px #00000032"
      );
      this.renderer.setStyle(loadingContainer, "z-index", "999");
      this.createSpinner(loadingContainer);
    }
    if (changes.fullLoading) {
      //console.log( this.targetEl.nativeElement)
      const container = this.targetEl.nativeElement;
      //container.style.opacity = this.fullLoading ? "0" : "100%";
      const div = container.querySelector("." + this.uid);
      if (div) {
        this.renderer.setStyle(
          div,
          "display",
          this.fullLoading ? "flex" : "none"
        );
      }
    }
  }

  private createSpinner(loadingContainer): void {
    if (!this.spinner) {
      this.spinner = this.viewContainerRef.createComponent(this.spinnerFactory);
      this.spinner.instance.color = this.color;
      this.spinner.instance.diameter = 50;
      this.spinner.instance.mode = "indeterminate";
      this.renderer.appendChild(
        loadingContainer,
        this.spinner.instance._elementRef.nativeElement
      );
      this.renderer.appendChild(this.targetEl.nativeElement, loadingContainer);
    }
  }

  private destroySpinner(): void {
    if (this.spinner) {
      this.spinner.destroy();
      this.spinner = null;
    }
  }
}
