import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NgForm } from "@angular/forms";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import { PromoCode } from "src/app/shared/models/interfaces";
import { PromoCodesService } from "../../services/promo-codea.service";
import { DataService } from "src/app/shared/services/data.service";
import { Router } from "@angular/router";
export const MY_FORMATS = {
  parse: {
    dateInput: "LL",
  },
  display: {
    dateInput: "LL",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};
@Component({
  selector: "app-reactivate-promo-code-dialog",
  templateUrl: "./reactivate-promo-code-dialog.component.html",
  styleUrls: ["./reactivate-promo-code-dialog.component.scss"],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "ar" },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ReactivatePromoCodeDialogComponent {
  isLoading = false;
  maxDate: Date = new Date(Date.now() + 1 * 24 * 60 * 60 * 1000);
  expireDateNo: Date;
  promoCodeDate = {
    expired_at: undefined,
  };
  constructor(
    public dialogRef: MatDialogRef<ReactivatePromoCodeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _promoCodesService: PromoCodesService,
    public dataService: DataService,
    private router: Router
  ) { }

  // add new promo code
  async addPromoCodeForm(promoCode: NgForm) {
    this.isLoading = true;
    let obj: PromoCode = {
      title: this.data.promoCodeData.title,
      discountPercentage: +this.data.promoCodeData.discountPercentage,
      expired_at: new Date(new Date(new Date(promoCode.form.value.expired_at).toISOString()).setHours(2, 0, 0, 0)).toJSON(),
    };

    try {
      await this._promoCodesService.editPromoCode(
        obj,
        this.data.promoCodeData.id
      );
      this.router
        .navigateByUrl("/", { skipLocationChange: true })
        .then(() => {
          this.router.navigate(["/services/promo-code"]);
        })
        .then(() => {
          this.dataService.notification("تم إعادة تفعيل كود الخصم بنجاح.", 'success-snackbar');
        });

      this.closeDialog(true);
    } catch (error) { }

    this.isLoading = false;
  }

  closeDialog(data?: boolean) {
    this.dialogRef.close(data);
  }
}
