import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import slugify from "slugify";

import { CasesService } from "src/app/cms/services/cases.service";
import {
  addDuplicatedError,
  removeDuplicatedErrorOnUpdate,
} from "src/app/shared/helpers/formControlers";
import {
  CaseQuestion,
  CaseQuestionAnswer,
  QuestionType,
  SavedCaseQuestion,
} from "src/app/shared/models/interfaces";

const slugOptions = {
  replacement: "_",
  strict: true,
  lower: true,
};

@Component({
  selector: "app-save-case-question-dialog",
  templateUrl: "./save-case-question-dialog.component.html",
  styleUrls: ["./save-case-question-dialog.component.scss"],
})
export class SaveCaseQuestionDialogComponent implements OnInit, OnDestroy {
  isLoading = false;

  saveCaseQuestionForm = this._fb.group({
    title: [this.data.question?.title, Validators.required],
    type: [this.data.question?.type, Validators.required],
    isRequired: [this.data.question?.isRequired, Validators.required],
    status: [this.data.question?.status, Validators.required],
    dependent: [this.data.question?.dependent, Validators.required],
    section_id: this.data.sectionId,
  });

  private _subscriptionList: Subscription[] = [];
  questionsType: QuestionType[] = [];
  dependQuestions: QuestionType[] = [];

  listLike = ["check", "radio", "list"];

  constructor(
    public dialogRef: MatDialogRef<SaveCaseQuestionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fb: FormBuilder,
    private _casesService: CasesService
  ) { }

  ngOnInit() {
    this._initQuestionTypes();

    // If the type of current question is list-like, show the answer list
    this.initAnswerList();

    this.getDependQuestions();
    this.AddDependedQuestion();
  }

  get answerList() {
    return this.saveCaseQuestionForm.controls["list"] as FormArray;
  }

  addAnswer(answer = "", slug = "") {
    const answerForm = this._fb.group({
      answer: [answer, Validators.required],
      slug,
    });

    this.answerList.push(answerForm);
  }

  deleteAnswer(answerIndex: number) {
    this.answerList.removeAt(answerIndex);
  }

  clearAnswerList() {
    this.answerList.clear();
  }

  async _initQuestionTypes() {
    let questionTypesObj = await this._casesService.getQuestionTypes();

    this.questionsType = Object.values(questionTypesObj) as QuestionType[];
  }

  initAnswerList() {
    if (!this.data.question) return;

    this.changeTypeHandler(this.data.question.type);
  }

  private _addSubscriptions() {
    // Remove errors if user update the form values
    const titleSub = removeDuplicatedErrorOnUpdate(
      this.saveCaseQuestionForm.controls.title
    );
    this._subscriptionList.push(titleSub);

    const listSub = removeDuplicatedErrorOnUpdate(
      this.saveCaseQuestionForm.controls.list
    );

    if (listSub) this._subscriptionList.push(listSub);
  }

  private _unsubscribeAllSubscriptions() {
    this._subscriptionList.forEach((sub) => sub.unsubscribe());
    this._subscriptionList = [];
  }

  changeTypeHandler(questionType: string) {
    if (questionType === 'calendar') {
      this.saveCaseQuestionForm.addControl("calendarType", new FormControl(null, [Validators.required]));
    } else {
      this.saveCaseQuestionForm.removeControl("calendarType");
    }

    if (!this.listLike.includes(questionType)) {
      this.saveCaseQuestionForm.removeControl("list");
    } else {
      this.saveCaseQuestionForm.addControl("list", this._fb.array([]));
      // Reset the list-like inputs
      this.clearAnswerList();

      // The initail input for new question answer
      if (!this.data.question?.list) this.addAnswer();
      // Load all list answers of question to inputs
      else
        this.data.question?.list?.map((answerObj: CaseQuestionAnswer) =>
          this.addAnswer(answerObj.value, answerObj.slug)
        );
    }



    this._unsubscribeAllSubscriptions();
    this._addSubscriptions();
  }

  private _formattedQuestionData() {
    let questionData = JSON.parse(
      JSON.stringify(this.saveCaseQuestionForm.value)
    ) as SavedCaseQuestion;

    if (this.listLike.includes(questionData.type)) {
      // Transform the list data from [{answer:xx, slug:xx}] to [xx]
      const answersPart = questionData.list.map(
        (answerObj) => answerObj.answer
      );

      // If user enters dup
      if (new Set(answersPart).size !== answersPart.length) {
        return addDuplicatedError(this.saveCaseQuestionForm.controls.list);
      }

      // If we update an exist list-like question
      if (this.data.question) {
        const answerList = [];
        questionData.list.forEach((answerObj) =>
          answerList.push({
            value: answerObj.answer,
            slug:
              answerObj.slug || // if the answer exist before
              this._slugifyNewAnswer(answerObj.answer, answerList),
          })
        );

        questionData.list = answerList;
      }
      // If we create a new list-like question
      else {
        questionData.list = answersPart;
      }
    }

    return questionData;
  }

  private _slugifyNewAnswer(answer: string, answers: CaseQuestionAnswer[]) {
    const randomNum = Math.floor(Math.random() * 1000);
    let slug = slugify(answer, slugOptions);

    const duplicatedAnswerIndex = answers.findIndex((answer) => {
      return answer.slug == slug;
    });

    // If the anwser was exist before randomize the slug
    if (duplicatedAnswerIndex != -1) {
      slug = slugify(`${answer}_${randomNum}`, slugOptions);
    }
    return slug;
  }

  async saveQuestion() {
    const questionData = this._formattedQuestionData();
    if (!questionData) return;
    this.isLoading = true;
    try {
      if (this.data.question?.id) {
        // Transform the list data from [{value:xx, slug:xx}] to [xx]
        const questionDataClone = JSON.parse(
          JSON.stringify(questionData)
        ) as SavedCaseQuestion;

        if (this.listLike.includes(questionData.type)) {
          questionDataClone.list = questionDataClone.list.map(
            (question) => question.value
          );
        }

        await this._casesService
          .updateCaseQuestion(questionDataClone, this.data.question.id)
          .toPromise();

        this.closeDialog({ ...questionData, id: this.data.question.id });
      } else {
        const savedQuestion = await this._casesService
          .addCaseQuestion(questionData)
          .toPromise();
        this.closeDialog(savedQuestion);
      }
    } catch (err) {
      // console.error(err);
      if (
        err.status == 400 &&
        err.error.error.message == "There is already question with that name"
      )
        addDuplicatedError(this.saveCaseQuestionForm.controls.title);
    }
    this.isLoading = false;
  }

  closeDialog(data?: Partial<CaseQuestion>) {
    this.dialogRef.close(data);
  }

  getDependQuestions() {
    this._casesService
      .getDependQuestions(this.data.sectionId)
      .subscribe((data: any) => {
        this.dependQuestions = data;
      });
  }

  AddDependedQuestion() {
    const isDepend = this.saveCaseQuestionForm.controls.dependent.value;
    if (isDepend) {
      this.saveCaseQuestionForm.addControl(
        "parent_id",
        new FormControl(this.data.question?.parent_id, [Validators.required])
      );
    } else {
      this.saveCaseQuestionForm.removeControl("parent_id");
    }
  }

  ngOnDestroy() {
    this._unsubscribeAllSubscriptions();
  }
}
