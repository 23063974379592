import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SavedUserData } from "src/app/shared/models/interfaces";

@Component({
  selector: "app-save-user-header",
  templateUrl: "./save-user-header.component.html",
  styleUrls: ["./save-user-header.component.scss"],
})
export class SaveUserHeaderComponent implements OnInit {
  @Input() editableSection: SavedUserData;
  @Input() selectedSection: SavedUserData;
  @Input() sectionName: string;
  @Input() isAddNewUser = false;
  @Input() disableEdit = false;
  @Input() loading = false;
  @Output() selectSection = new EventEmitter<SavedUserData>();

  constructor() {}

  ngOnInit(): void {}
}
