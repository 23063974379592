import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { MatTabChangeEvent, MatTabGroup } from "@angular/material/tabs";
import { TranslateService } from "@ngx-translate/core";
import { MatTable } from "@angular/material/table";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";

import { map } from "rxjs/internal/operators/map";

import { CasesService } from "src/app/cms/services/cases.service";
import { SaveCaseSectionDialogComponent } from "src/app/cms/cases-types/save-case-section-dialog/save-case-section-dialog.component";
import { SaveCaseQuestionDialogComponent } from "src/app/cms/cases-types/save-case-question-dialog/save-case-question-dialog.component";
import { ConfirmDialogComponent } from "src/app/shared/components/confirm-dialog/confirm-dialog.component";
import { CaseSection, CaseQuestion } from "src/app/shared/models/interfaces";
import { columns } from "./columns";
import { DeleteConfirmDialog } from "src/app/shared/delete-confirm-dialog/delete-confirm-dialog.component";

@Component({
  selector: "app-case-questions",
  templateUrl: "./case-questions.component.html",
  styleUrls: ["./case-questions.component.scss"],
})
export class CaseQuestionsComponent implements OnInit {
  @ViewChild("sectionTabs", { static: false }) sectionTabs: MatTabGroup;
  @ViewChild(MatTable) private _table: MatTable<any>;

  isLoading = false;
  caseId: number;
  caseTitle = "";
  activeSectionId: number;
  caseSectionList: CaseSection[] = [];
  caseQuestionList: { [sectionId: number]: CaseQuestion[] } = {};

  readonly columns = columns;

  displayedColumns = [...this.columns.map((c) => c.columnDef), "actions"];

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _translate: TranslateService,
    private _casesService: CasesService,
    private _dialog: MatDialog
  ) {}

  ngOnInit() {
    this.caseId = Number(this._activatedRoute.snapshot.paramMap.get("caseId"));

    this._setCaseTitle();

    this._initQuestionList(this.caseId);
    this.isLoading = false;
  }

  private async _setCaseTitle() {
    // If user route from cases page to this page
    this._activatedRoute.paramMap
      .pipe(map(() => window.history.state))
      .subscribe((state) => {
        if (state.caseTitle) this.caseTitle = state.caseTitle;
      });

    // If user enter this page from url bar
    if (!this.caseTitle) {
      this.caseTitle = (
        await this._casesService.getCase(this.caseId).toPromise()
      ).title;
    }
  }

  private async _initQuestionList(caseId: number) {
    this.caseSectionList = await this._casesService
      .getCaseSections(caseId)
      .toPromise();

    // Avoid if case has not section yet
    if (!this.caseSectionList.length) return;
    const firstSectionId = this.caseSectionList[0].id;

    this._setQuestionList(firstSectionId);
  }

  private async _setQuestionList(sectionId: number) {
    this.isLoading = true;
    this.activeSectionId = sectionId;

    // Avoid fetch questions if loaded before
    if (this.caseQuestionList[sectionId]) return (this.isLoading = false);
    const questionList = await this._casesService
      .getCaseQuestions(sectionId)
      .toPromise();

    this.caseQuestionList[sectionId] = questionList;
    this.isLoading = false;
  }

  private _navigateToNewSection() {
    let activeTabIndex = this.caseSectionList.length - 1;
    // Skip if the section list is empty
    if (!this.caseSectionList.length) activeTabIndex = 0;
    setTimeout(() => (this.sectionTabs.selectedIndex = activeTabIndex), 0);
  }

  openAddCaseSectionDialog() {
    this._dialog
      .open(SaveCaseSectionDialogComponent, {
        direction: this._translate.currentLang == "ar" ? "rtl" : "ltr",
        panelClass: "",
        data: {
          btnText: "DataTable.Save",
          caseId: this.caseId,
        },
      })
      .afterClosed()
      .subscribe((newCaseSection: CaseSection) => {
        if (newCaseSection) this.caseSectionList.push(newCaseSection);
        this._navigateToNewSection();
      });
  }

  openEditCaseSectionDialog(e: Event, sectionTitle: string, sectionId: number) {
    e.stopPropagation();
    this._dialog
      .open(SaveCaseSectionDialogComponent, {
        direction: this._translate.currentLang == "ar" ? "rtl" : "ltr",
        panelClass: "",
        data: {
          btnText: "DataTable.modify",
          caseId: this.caseId,
          sectionTitle,
          sectionId,
        },
      })
      .afterClosed()
      .subscribe((updatedSection: CaseSection) => {
        if (updatedSection) {
          const updatedSectionIndex = this.caseSectionList.findIndex(
            (sectionItem) => sectionItem.id == updatedSection.id
          );
          this.caseSectionList[updatedSectionIndex] = updatedSection;
        }
      });
  }

  openDeleteCaseSectionDialog(
    e: Event,
    sectionTitle: string,
    sectionId: number
  ) {
    e.stopPropagation();
    this._dialog
      .open(DeleteConfirmDialog, {
        direction: this._translate.currentLang == "ar" ? "rtl" : "ltr",
        panelClass: "",
        autoFocus:false,
        data: {
          caseId: this.caseId,
          sectionTitle,
          sectionId,
          message:"هل انت متأكد من انك تريد حذف هذه الصفحة ؟",
          icon:"delete_forever"
        },
      })
      .afterClosed()
      .subscribe((accept: boolean) => {
        if(accept){
          this._casesService.deleteCaseSection(sectionId).subscribe((data)=>{
           this.caseSectionList = this.caseSectionList.filter((section)=>{
            return section?.id != sectionId
           })
          })
        }
      });
  }

  onChangeSection(e: MatTabChangeEvent) {
    const sectionId = Number(e.tab.textLabel);
    this._setQuestionList(sectionId);
  }

  openAddCaseQuestionDialog(sectionId: number) {
    this._dialog
      .open(SaveCaseQuestionDialogComponent, {
        direction: this._translate.currentLang == "ar" ? "rtl" : "ltr",
        panelClass: "save-case-dialog",
        data: {
          title: this._translate.instant(
            "save-case-question-dialog.add-question"
          ),
          sectionId,
        },
      })
      .afterClosed()
      .subscribe((newQuestion: CaseQuestion) => {
        if (newQuestion) {
          this.caseQuestionList[sectionId].push(newQuestion);
          this._table.renderRows();
        }
      });
  }

  openEditQuestionDialog(sectionId: number, question: CaseQuestion) {
    this._dialog
      .open(SaveCaseQuestionDialogComponent, {
        direction: this._translate.currentLang == "ar" ? "rtl" : "ltr",
        panelClass: "save-case-dialog",
        data: {
          title: this._translate.instant(
            "save-case-question-dialog.edit-question"
          ),
          sectionId,
          question,
        },
      })
      .afterClosed()
      .subscribe((updatedQuestion: CaseQuestion) => {
        if (updatedQuestion) {
          const updatedQuestionIndex = this.caseQuestionList[
            sectionId
          ].findIndex((questionItem) => questionItem.id == updatedQuestion.id);

          this.caseQuestionList[sectionId][updatedQuestionIndex] =
            updatedQuestion;

          this._table.renderRows();
        }
      });
  }

  openDeleteQuestionDialog(sectionId: number, questionId: number) {
    this._dialog
      .open(ConfirmDialogComponent, {
        direction: this._translate.currentLang == "ar" ? "rtl" : "ltr",
        panelClass: "confirm-dialog",
        data: {
          content: this._translate.instant(
            "confirm-dialog.are-you-want-delete-question"
          ),
          icon: "delete_forever",
        },
      })
      .afterClosed()
      .subscribe(async (isDeleted) => {
        if (isDeleted) {
          await this._casesService.deleteCaseQuestion(questionId).toPromise();

          this.caseQuestionList[sectionId] = this.caseQuestionList[
            sectionId
          ].filter((question) => question.id != questionId);
        }
      });
  }

  async reorderQuestions(event: CdkDragDrop<CaseQuestion[]>) {
    const { previousIndex, currentIndex } = event;

    if (previousIndex === currentIndex) return;

    const movedQuestion = event.previousContainer.data[previousIndex];

    this.isLoading = true;
    try {
      await this._casesService
        .reorderCaseQuestion({
          currentOrder: currentIndex,
          previousOrder: previousIndex,
          questionId: movedQuestion.id,
          sectionId: this.activeSectionId,
        })
        .toPromise();

      moveItemInArray(
        this.caseQuestionList[this.activeSectionId],
        previousIndex,
        currentIndex
      );

      this._table.renderRows();
    } catch (error) {
      console.error(error);
    }

    this.isLoading = false;
  }
}
