import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { AddPlanDialogComponent } from '../../dialogs/add-plan-dialog/add-plan-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ServiceList } from "src/app/shared/models/interfaces";
import { ServicesService } from "../../services/services.service";
import { DataService } from 'src/app/shared/services/data.service';
import { Subscription } from 'rxjs';
import { UpgradeServiceDialogComponent } from '../../dialogs/upgrade-service-dialog/upgrade-service-dialog.component';

@Component({
  selector: "app-service-plan-page",
  templateUrl: "./service-plan-page.component.html",
  styleUrls: ["./service-plan-page.component.scss"],
})
export class ServicePlanPageComponent {
  servicePlansList: ServiceList[] = [];
  isLoading = false;
  serviceTitle: string;
  serviceId: number;
  serviceListSub$!: Subscription;
  dialogSub$!: Subscription;
  routeSub$!: Subscription;
  service: ServiceList;
  serviceUpgradeSub$!: Subscription;
  upgradeService: number | null
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _dialog: MatDialog,
    private _servicesService: ServicesService,
    public dataService: DataService
  ) {
    this.serviceId = Number(
      this._activatedRoute.snapshot.paramMap.get("serviceId")
    );

    // If user route from service page to this page
    this.routeSub$ = this._activatedRoute.paramMap
      .pipe(map(() => window.history.state))
      .subscribe((state) => {
        if (state.serviceTitle) this.serviceTitle = state.serviceTitle;
      });

    this.getServicePlans();
    this.getUpgradeValue();
  }

  // get service plans
  async getServicePlans() {
    this.serviceListSub$ = this._servicesService
      .getServiceId(this.serviceId)
      .subscribe((services: ServiceList) => {
        this.service = services;
        this.serviceTitle = services.title
        this.servicePlansList = services.plans ? services.plans : [];
      });
  }

  // get upgrade service value
  async getUpgradeValue() {
    this.serviceUpgradeSub$ = this._servicesService
      .getUpgradeServiceValue()
      .subscribe((res: any) => {
        this.upgradeService = res.upgradeService
      });
  }

  // add new plan
  async openAddPlanDialog() {
    this.dialogSub$ = this._dialog
      .open(AddPlanDialogComponent, {
        direction: "rtl",
        panelClass: "save-case-dialog",
        autoFocus: false,
        data: {
          serviceId: this.serviceId
        },
      })
      .afterClosed()
      .subscribe((newPlan: boolean) => {
        if (newPlan) {
          this.dataService.notification("تم إضافة خطة جديدة بنجاح", 'success-snackbar');
          this.getServicePlans();
        }
      });
  }


  // upgrade service
  async openUpgradeServiceDialog() {
    this.dialogSub$ = this._dialog
      .open(UpgradeServiceDialogComponent, {
        direction: "rtl",
        panelClass: "save-case-dialog",
        autoFocus: false,
        data: {
          upgradeService: this.upgradeService
        },
      })
      .afterClosed()
      .subscribe((newPlan: boolean) => {
        if (newPlan) {
          this.dataService.notification("تم تعديل مبلغ الترقية بنجاح", 'success-snackbar');
          this.getUpgradeValue();
        }
      });
  }

  // handle update plans list when delete plan
  updatePlan(response: boolean) {
    this.getServicePlans();
  }

  ngOnDestroy() {
    if (this.serviceListSub$) this.serviceListSub$.unsubscribe();
    if (this.dialogSub$) this.dialogSub$.unsubscribe();
    if (this.routeSub$) this.routeSub$.unsubscribe();
    if (this.serviceUpgradeSub$) this.serviceUpgradeSub$.unsubscribe();
  }
}
