import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { DataService } from "../services/data.service";

@Component({
  selector: "image-edit",
  templateUrl: "./image-edit.component.html",
  styleUrls: ["./image-edit.component.scss"],
})
export class ImageEdit {
  imageChangedEvent: any = "";
  croppedImage: any = "";

  constructor(
    public dialogRef: MatDialogRef<ImageEdit>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dataService: DataService
  ) {
    this.croppedImage = data.imageUrl;
    //console.log(data)
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    this.dataService.notification("Please select image.");
    // show message
  }
}
