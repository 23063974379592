import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment-timezone";
@Pipe({
  name: "arabicDate",
})
export class ArabicDatePipe implements PipeTransform {
  transform(value: any, format: string, lang?: string, ...args: unknown[]): unknown {
    moment.locale(lang ? lang : "ar");
    return moment(new Date(value)).format(format);
  }
}
