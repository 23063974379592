import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { getHeadersWithToken } from "src/app/shared/helpers/getHeadersWithToken";
import { environment } from "src/environments/environment";
import { PromoCode } from "src/app/shared/models/interfaces";

@Injectable({
  providedIn: "root",
})
export class PromoCodesService {
  private readonly _api = environment.apiHost;

  constructor(private _http: HttpClient) { }

  // add new promo code
  addNewPromoCode(promoCodeData: PromoCode) {
    return this._http.post<PromoCode>(this._api + `admin/coupons`, promoCodeData, {
      headers: getHeadersWithToken(),
    }).toPromise()
  }

  // delete promo code
  deletePromoCode(id: number) {
    return this._http.delete(this._api + `admin/coupons/${id}`, {
      headers: getHeadersWithToken(),
    });
  }

  // edit promo code
  editPromoCode(promoCodeData: PromoCode, id: number) {
    return this._http.patch(this._api + `admin/coupons/${id}`, promoCodeData, {
      headers: getHeadersWithToken(),
    }).toPromise()
  }
}
