import { Component, OnInit, ViewChild } from "@angular/core";
import {
  DataTableComponentBase,
  Filter,
  TableSchema,
  TableService,
  TableTypes,
} from "@kortobaa-front/k-ng-datatable";
import { AuthService } from "src/app/core/auth.service";
import { DataService } from "src/app/shared/services/data.service";
import { HttpClient } from "@angular/common/http";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";

import { DataTableComponent } from "@kortobaa-front/k-ng-datatable";
import * as moment from "moment-timezone";
import { FilterDialogComponent } from "../dialog/filter-dialog/filter-dialog.component";
import { ServicesService } from "src/app/services-types/services/services.service";
import { Subscription } from "rxjs";
import { ServiceList } from "src/app/shared/models/interfaces";
import { Location } from "@angular/common";
import { Router } from "@angular/router";

@Component({
  selector: "app-payments",
  templateUrl: "./payments.component.html",
  styleUrls: ["./payments.component.scss"],
})
export class PaymentsComponent extends DataTableComponentBase {
  moduleName = "payments";
  url = "payments";
  filters: any = {
    limit: 10,
    offset: 0,
    order: ["created_at DESC"],
    where: {
      and: [],
    },
  };
  dataSource;
  isDataLoading = false;
  displayedColumns: TableSchema[] = [
    {
      title: "service_name",
      id: "service_title",
      type: TableTypes.text,
      editable: false,
      notOnFilter: true,
    },
    {
      title: "user-name",
      id: "username",
      type: TableTypes.text,
      editable: false,
      notOnFilter: true,
    },
    {
      title: "payment-id",
      id: "paymentId",
      type: TableTypes.number,
      editable: false,
      notOnFilter: true,
    },
    {
      title: "payment-amount",
      id: "amount",
      type: TableTypes.text,
      editable: false,
      notOnFilter: true,
    },
    {
      title: "payment-date",
      id: "created_at",
      type: TableTypes.text,
      editable: false,
      notOnFilter: true,
    },
    {
      title: "payment-status",
      id: "status",
      type: TableTypes.select,
      list: [
        { title: "ناجحة", value: "paid", fontColor: "#19C430" },
        { title: "مرفوضة", value: "failed", fontColor: "#AB1212" },
        { title: "قيد التحقيق", value: "pending", fontColor: "#EB9807" },
      ],
      editable: false,
      notOnFilter: true,
    },
    {
      title: "case-actions",
      id: "_",
      type: TableTypes.select,
      isActionsList: true,
      list: [
        { title: "chooses-action", value: "_" },
        { title: "download-pdf", value: "download-pdf" },
      ],
      editable: false,
      notOnFilter: true,
    },
  ];
  serviceListSub$!: Subscription;
  dialogSub$!: Subscription;
  serviceList: ServiceList[] = [];
  filterPayment!: any;
  filterPaymentObj: any = {};
  searchFilter!: any;
  @ViewChild(DataTableComponent)
  private _dataTableComponent: DataTableComponent;

  constructor(
    public authService: AuthService,
    private _tableService: TableService,
    private _dataService: DataService,
    private _dialog: MatDialog,
    private _http: HttpClient,
    private _translate: TranslateService,
    private _servicesService: ServicesService,
    private _location: Location,
    private router: Router
  ) {
    super();
    this._tableService.tableUrl = "admin/subscription-payments";
    this.getServiceList();
  }

  async getData(filters?: Filter) {
    // filters["where"] = this.filters.where;
    this.isDataLoading = true;
    try {
      let data = await this._tableService.getData<any>(filters).toPromise();
      this.dataSource = data.map((elem) => {
        elem["created_at"] = moment(new Date(elem["created_at"])).format("LL");
        elem["amount"] = `${elem["amount"]} ر.س`;
        return elem;
      });
    } catch (err) {
      // console.error(err);
    }
    this.isDataLoading = false;
  }

  getDataForAction(data) {
    this._tableService.SaveItem(data).subscribe(
      (row: any) => {
        this._tableService.redirect(this.url);
      },
      (error) => {
        this._dataService.notification(error.error.error.message);
      }
    );
  }

  async getExportData(data) {
    let filters = { ...this._dataTableComponent.backendFilter };
    if (filters["limit"]) delete filters["limit"];
    if (filters["offset"]) delete filters["offset"];
    try {
      let data = await this._tableService
        .getData<any>({ where: filters.where })
        .toPromise();
      let exportData = data.map((elem) => {
        elem["created_at"] = moment(new Date(elem["created_at"])).format("LL");
        elem["amount"] = `${elem["amount"]} ر.س`;
        return elem;
      });
      await this._dataTableComponent.downloadAllData(exportData);
    } catch (err) {
      // console.error(err);
    }
  }

  isIsoDate(arg0: any) {
    throw new Error("Method not implemented.");
  }

  async filterByDate(value: any) {
    let startDate = moment(value.startDate).add(3, "h").toDate()?.toISOString();
    let endDate = moment(value.endDate).add(3, "h").toDate()?.toISOString();
    this.filters["where"] = {
      and: [{ created_at: { gt: startDate } }, { created_at: { lt: endDate } }],
    };
    this.getData(this.filters);
  }

  async handleRowActions(data: { action: any; row: any }) {
    const { action, row } = data;
    const paymentPdf = row.invoiceUrl;

    if (action === "download-pdf") {
      window.open(paymentPdf, "_blank");
    }
  }

  // handle filter dialog
  async handleCustomFilter(data: any) {
    if (data == "customFilter") {
      this.dialogSub$ = this._dialog
        .open(FilterDialogComponent, {
          direction: "rtl",
          panelClass: "save-case-dialog",
          data: { serviceList: this.serviceList },
        })
        .afterClosed()
        .subscribe(async (filterPayment: any) => {
          if (filterPayment) {
            this.isDataLoading = true;
            this.handleFilter(filterPayment);
          }
        });
    }
  }

  // handle filteration
  async handleFilter(obj: any) {
    this.filterPayment = obj;
    let body: any = { and: [] };
    if (obj.dateToValue && obj.dateFromValue) {
      body.and = [
        { created_at: { lt: obj.dateToValue } },
        { created_at: { gt: obj.dateFromValue } },
      ];
    }
    if (obj.serviceId)
      body.and = [...body.and, { serviceId: `${obj.serviceId}` }];
    if (obj.paid && obj.failed && obj.pending) {
      body.and = [
        ...body.and,
        { status: { inq: ["paid", "failed", "pending"] } },
      ];
    } else if (obj.paid && obj.failed) {
      body.and = [...body.and, { status: { inq: ["paid", "failed"] } }];
    } else if (obj.paid && obj.pending) {
      body.and = [...body.and, { status: { inq: ["paid", "pending"] } }];
    } else if (obj.failed && obj.pending) {
      body.and = [...body.and, { status: { inq: ["failed", "pending"] } }];
    } else if (obj.paid) {
      body.and = [...body.and, { status: { inq: ["paid"] } }];
    } else if (obj.pending) {
      body.and = [...body.and, { status: { inq: ["pending"] } }];
    } else if (obj.failed) {
      body.and = [...body.and, { status: { inq: ["failed"] } }];
    }
    for (let item of body.and) {
      if (item.created_at && this.filterPayment.filterBy == "specific") {
        if (item.created_at.lt) {
          this.filterPaymentObj.dateToValue = item.created_at.lt;
        }
        if (item.created_at.gt) {
          this.filterPaymentObj.dateFromValue = item.created_at.gt;
        }
      }
      if (item.serviceId) {
        this.filterPaymentObj.serviceId = this.serviceList.find(
          (res) => res.id == item.serviceId
        ).title;
      }
      if (item.status) {
        this.filterPaymentObj.status = item.status.inq;
      }
    }
    this.filters["where"] = {
      ...this.filters["where"],
      ...body,
    };
    if (this.searchFilter) {
      this.filters.where.and = [this.searchFilter, ...this.filters.where.and];
    }
    this._dataTableComponent.backendFilter = this.filters;
    this.getData(this.filters);
    this._dataTableComponent.tableDataLength = (
      await this._tableService.getDataCount(this.filters["where"]).toPromise()
    ).count;
    this.isDataLoading = false;
  }

  //remove filter - type and update filter and display it from api
  removeFilter(value: any) {
    if (value == "dateFromValue" || value == "dateToValue") {
      delete this.filterPaymentObj["dateFromValue"];
      delete this.filterPaymentObj["dateToValue"];
      delete this.filterPayment["filterBy"];
      delete this.filterPayment["dateFromValue"];
      delete this.filterPayment["dateToValue"];
    } else {
      delete this.filterPaymentObj[value];
      delete this.filterPayment[value];
    }

    if (this.filterPaymentObj?.status?.length == 1) {
      delete this.filterPaymentObj["status"];
    }

    if (Object.keys(this.filterPaymentObj).length === 0) {
      this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
        this.router.navigate(["/payments"]);
      });
    } else {
      this.handleFilter(this.filterPayment);
    }
  }

  // get service list
  getServiceList() {
    this.serviceListSub$ = this._servicesService
      .getServiceList()
      .subscribe((res) => {
        this.serviceList = res;
      });
  }

  // handle search
  async handleSearchChange(value: string) {
    // TODO: refactor all this logic, it's just a workaround to manipulate the data table component directly because of the poor API
    this.isDataLoading = true;
    if (!value) this._dataTableComponent.paginator.firstPage();

    this.filters.where.and.filter((res) => {
      if (res.or) {
        this.filters.where.and.shift();
      }
    });

    this.searchFilter = {
      or: [
        { username: { regexp: `/.*${value}.*/i` } },
        { paymentId: { regexp: `/.*${value}.*/i` } }
      ],
    };

    this.filters.where.and = [this.searchFilter, ...this.filters.where.and];

    this._dataTableComponent.backendFilter = this.filters;

    this.getData(this._dataTableComponent.backendFilter);

    this._dataTableComponent.tableDataLength = (
      await this._tableService
        .getDataCount(this._dataTableComponent.backendFilter.where)
        .toPromise()
    ).count;

    this.isDataLoading = false;
  }

  downloadPDF() {
    // console.log("test");
  }

  ngOnDestroy() {
    if (this.serviceListSub$) this.serviceListSub$.unsubscribe();
    if (this.dialogSub$) this.dialogSub$.unsubscribe();
  }
}
