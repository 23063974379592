import { Component } from "@angular/core";
import { MenuData } from "src/app/shared/models/interfaces";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent {
  isSideNavOpened = true;

  menuData: MenuData[] = [
    {
      title: "home",
      url: "/home",
      iconImageName: "home.png",
    },
    {
      title: "user-management",
      url: "/users",
      iconImageName: "users.png",
      child: [
        {
          title: "user-management",
          url: "/user-management",
          permission: "users",
        },
        {
          title: "supervisor-management",
          url: "/supervisor-management",
          permission: "users",
        },
        {
          title: "lawyer-management",
          url: "/lawyer-management",
          permission: "users",
        },
        {
          title: "conciliator-management",
          url: "/conciliator-management",
          permission: "users",
        },
        // NOTE: don't remove maybe add later
        // {
        //   title: "call-center-management",
        //   url: "/call-center-management",
        //   permission: "users",
        // },
      ],
    },
    {
      title: "user-cases",
      url: "/user-cases",
      iconImageName: "cases.png",
    },
    {
      title: "payments",
      url: "/payments",
      iconImageName: "payments.png",
    },
    {
      title: "cms",
      url: "/cms",
      iconImageName: "cms.png",
      child: [
        {
          title: "cases-types",
          url: "/cases-types",
          permission: "casesTypes",
        },
        {
          title: "pages",
          url: "/pages",
          permission: "pages",
        },
        {
          title: "faqCategories",
          url: "/faqCategories",
          permission: "faqCategories",
        },
        {
          title: "faq",
          url: "/faq",
          permission: "faq",
        },
      ],
    },
    {
      title: "reports",
      url: "/reports",
      iconImageName: "reports.png",
      child: [
        {
          title: "lawyers-reports",
          url: "/lawyers-reports",
          permission: "lawyersReports",
        },
        {
          title: "conciliator-reports",
          url: "/conciliator-reports",
          permission: "conciliatorReports",
        },
        {
          title: "cases-types-reports",
          url: "/cases-types-reports",
          permission: "casesTypesReports",
        },
        {
          title: "cases-status-reports",
          url: "/cases-status-reports",
          permission: "casesStatusReports",
        },
        {
          title: "evaluations-cases-reports",
          url: "/evaluations-cases-reports",
          permission: "evaluationsCasesReports",
        },
        {
          title: "evaluations-inquiries-reports",
          url: "/evaluations-inquiries-reports",
          permission: "evaluationsInquiriesReports",
        },
        {
          title: "reasons-cases-reports",
          url: "/reasons-cases-reports",
          permission: "reasonsCasesReports",
        }
      ],
    },
    {
      title: "services-type",
      url: "/services",
      iconImageName: "services.png",
    },
  ];
}
