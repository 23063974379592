import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "image-dialog",
  templateUrl: "./image-dialog.component.html",
  styleUrls: ["./image-dialog.component.scss"],
})
export class ImageDialog {
  constructor(
    public dialogRef: MatDialogRef<ImageDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  endMeeting(id) {
    //id parameter may be one meeting id or a list of meetings IDs
    let ids = [];
    /* console.log(id); */
    if (id instanceof Array) {
      id.forEach((ID) => {
        //this.endMeetingOrAll(ID)
        ids.push(ID);
      });
    } else {
      //this.endMeetingOrAll(id)
      ids.push(id);
    }

    this.dialogRef.close(ids);
  }

  deleteMember(id) {
    this.dialogRef.close({
      userId: id,
      roomId: this.data.roomId,
      roomDbId: this.data.roomDbId,
    });
  }
}
