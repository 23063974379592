import { CaseQuestion } from "src/app/shared/models/interfaces";

export const columns = [
  {
    columnDef: "id",
    header: "quetsion-table.field-number",
    cell: (element: CaseQuestion) => `${element.id}`.padStart(2, "0"),
  },
  {
    columnDef: "title",
    header: "quetsion-table.question-title",
    cell: (element: CaseQuestion) => `${element.title}`,
  },
  {
    columnDef: "type",
    header: "quetsion-table.type-of-answer",
    cell: (element: CaseQuestion) =>
      `save-case-question-dialog.${element.type}`,
  },
  {
    columnDef: "isRequired",
    header: "quetsion-table.mandatory",
    cell: (element: CaseQuestion) => element.isRequired,
  },
  {
    columnDef: "status",
    header: "quetsion-table.activated",
    cell: (element: CaseQuestion) => element.status,
  },
];
