import { ServiceList } from './../../../shared/models/interfaces';
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DeleteConfirmDialog } from "src/app/shared/delete-confirm-dialog/delete-confirm-dialog.component";
import { ServicesService } from "../../services/services.service";
import { DataService } from "src/app/shared/services/data.service";
import { AddServiceDialogComponent } from '../../dialogs/add-service-dialog/add-service-dialog.component';

@Component({
  selector: "app-services-list",
  templateUrl: "./services-list.component.html",
  styleUrls: ["./services-list.component.scss"],
})
export class ServicesListComponent implements OnInit {
  @Input() service: ServiceList;
  @Output() updateServiceEvent = new EventEmitter<true>();

  constructor(
    private _servicesService: ServicesService,
    public dataService: DataService,
    private _dialog: MatDialog
  ) { }

  ngOnInit(): void { }

  // delete service
  openDeleteServiceDialog(e: Event) {
    e.stopPropagation();
    this._dialog
      .open(DeleteConfirmDialog, {
        direction: "rtl",
        panelClass: "",
        autoFocus: false,
        data: {
          message: "هل انت متأكد من انك تريد حذف الخدمة ؟ ",
          icon: "delete_forever",
        },
      })
      .afterClosed()
      .subscribe((accept: boolean) => {
        if (accept) {
          this._servicesService
            .deleteService(this.service.id)
            .subscribe((data) => {
              this.dataService.notification("تم حذف الخدمة بنجاح", 'success-snackbar');
              this.updateServiceEvent.emit(true)
            });
        }
      });
  }

  // edit service
  openEditServiceDialog(e: Event) {
    e.stopPropagation();
    this._dialog
      .open(AddServiceDialogComponent, {
        direction: "rtl",
        panelClass: "save-case-dialog",
        data: {
          service: this.service
        },
      })
      .afterClosed()
      .subscribe((editService: any) => {
        if (editService) {
          this.dataService.notification("تم تعديل بيانات الخدمة بنجاح", 'success-snackbar');
          this.updateServiceEvent.emit(true)
        }
      });
  }
}
