import { Component, OnInit } from "@angular/core";

import { AuthService } from "src/app/core/auth.service";
import {
  TableService,
  TableSchema,
  TableTypes,
  DataTableComponentBase,
  Filter,
} from "@kortobaa-front/k-ng-datatable";

@Component({
  selector: "app-faq-categories-list",
  templateUrl: "./faq-categories-list.component.html",
  styleUrls: ["./faq-categories-list.component.scss"],
})
export class FaqCategoriesListComponent
  extends DataTableComponentBase
  implements OnInit {
  moduleName = "faqCategories";
  url = "faqCategories";

  displayedColumns: TableSchema[] = [
    {
      title: "ID",
      id: "id",
      type: TableTypes.number,
      editable: false,
    },
    {
      title: "title",
      id: "title",
      type: TableTypes.text,
      editable: true,
    },
    {
      title: "Status",
      id: "status",
      type: TableTypes.booleanNumber,
      editable: true,
    },
  ];
  dataSource;
  isDataLoading = false;

  constructor(
    public authService: AuthService,
    private _tableService: TableService
  ) {
    super();
    this._tableService.tableUrl = "faq-categories";
  }

  async ngOnInit() {
    //
  }

  async getData(filters?: Filter) {
    this.isDataLoading = true;
    try {
      this.dataSource = await this._tableService.getData(filters).toPromise();
    } catch (err) {
      // console.error(err);
    }
    this.isDataLoading = false;
  }

  getDataForAction(data) {
    this._tableService.SaveItem(data).subscribe(
      (row: any) => {
        this._tableService.redirect(this.url);
      },
      (error) => {
        alert(error.error.error.message);
      }
    );
    //console.log('from main ',data)
  }
}
