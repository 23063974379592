import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { DataService } from "src/app/shared/services/data.service";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";

@Injectable()
export class UsersService {
  private headers: HttpHeaders;
  $encrypt: any;

  constructor(
    private translate: TranslateService,
    private http: HttpClient,
    private dataService: DataService,
    public dialog: MatDialog,
    private router: Router
  ) {
    //
  }

  async refreshToken() {
    const userData = this.dataService.currentUser;
    this.setHeaders();
    const tokenData = {
      refreshToken: userData["refreshToken"],
    };
    const url = environment.apiHost + "users/refresh-token";
    try {
      const newToken = await this.http
        .post(url, tokenData, { headers: this.headers })
        .toPromise();
      const accessToken = newToken["token"];
      const refreshToken = newToken["refreshToken"];
      const expiryDate = newToken["expiryDate"];
      let oldUserData = this.dataService.getUserData();
      oldUserData["token"] = accessToken;
      oldUserData["refreshToken"] = refreshToken;
      oldUserData["expiryDate"] = expiryDate;
      localStorage.setItem("token", accessToken);
      localStorage.setItem("User", JSON.stringify(oldUserData));
      this.dataService.currentUser = oldUserData;
      this.dataService.forceReloadSamePage();
      return true;
    } catch (error) {
      localStorage.clear();
      let msg: string = "Your session has expired, please login again";
      this.dataService.notification(msg);
      console.error(error);
      this.router.navigate(["/logout"]);
      return false;
    }
  }

  async checkUserAuth() {
    const userData = this.dataService.getUserData();
    if (userData) {
      const oldDate = new Date(userData["expiryDate"]);
      const currentDate = new Date();
      if (currentDate >= oldDate) {
        this.setHeadersWithToken();
        const url = `${environment.apiHost}users/validate`;
        try {
          await this.http
            .post(
              url,
              { token: this.dataService.getUserData() },
              { headers: this.headers }
            )
            .toPromise();
          return true;
        } catch (error) {
          return await this.refreshToken();
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  setHeaders() {
    this.headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
  }

  setHeadersWithToken() {
    const userData = this.dataService.getUserData();
    this.headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + userData,
    });
  }

  //update user info
  updateUserInfo(userData) {
    const userToken = this.dataService.getUserData();

    const headers = new HttpHeaders({
      Authorization: "Bearer " + userToken,
    });

    const url = environment.apiHost + "users/me";
    return this.http.patch(url, userData, { headers: headers });
  }

  getHeaderswithToken(){
    const userToken = localStorage.getItem('token');
    return new HttpHeaders({
      Authorization: "Bearer " + userToken,
    });
  }

  //get user data
  getMyInfo() {
    const userToken = this.dataService.getUserData();
    const headers = new HttpHeaders({
      Authorization: "Bearer " + userToken,
    });

    const url = environment.apiHost + "users/me";
    return this.http.get(url, { headers: headers });
  }

  sendVerificationCode(userEmail) {
    this.setHeaders();
    const userData = {
      email: userEmail,
    };
    const url = environment.apiHost + "users/reset-password";
    return this.http.post(url, userData, { headers: this.headers });
  }

  validateResetCode(code) {
    this.setHeaders();
    const userData = {
      token: code,
    };
    const url = environment.apiHost + "users/reset-verify";
    return this.http.post(url, userData, { headers: this.headers });
  }
}
