import { NgModule } from "@angular/core";
import { LayoutComponent } from "./layout/layout.component";
import { HeaderComponent } from "./layout/header/header.component";
import { MainComponent } from "./layout/main/main.component";
import { RouterModule } from "@angular/router";
import { SharedModule } from "../shared/shared.module";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatListModule } from "@angular/material/list";
import { DataService } from "../shared/services/data.service";
import { MatCardModule } from "@angular/material/card";
import { MatBadgeModule } from "@angular/material/badge";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatExpansionModule } from "@angular/material/expansion";
import { MenuComponent } from "./layout/menu/menu.component";
import { MatDividerModule } from "@angular/material/divider";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateModule } from "@ngx-translate/core";
import { SidenavService } from "./layout/sidenav.service";
import { FlexLayoutModule } from "@angular/flex-layout";

@NgModule({
  imports: [
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatDialogModule,
    MatListModule,
    MatCardModule,
    MatBadgeModule,
    MatSidenavModule,
    MatExpansionModule,
    MatDividerModule,
    SharedModule,
    RouterModule,
    TranslateModule,
    FlexLayoutModule,
  ],
  declarations: [
    LayoutComponent,
    HeaderComponent,
    MainComponent,
    MenuComponent,
  ],
  exports: [LayoutComponent],
  providers: [DataService, SidenavService],
})
export class UiModule {}
