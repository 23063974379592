import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { getHeadersWithToken } from "src/app/shared/helpers/getHeadersWithToken";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-reasons-cases-reports",
  templateUrl: "./reasons-cases-reports.component.html",
  styleUrls: ["./reasons-cases-reports.component.scss"],
})
export class ReasonsCasesReportsComponent implements OnInit {
  reportsCase: any;
  isLoading = false;
  platte = [
    {
      id: 1,
      color: "rgba(15, 153, 109, 0.24)",
    },
    {
      id: 2,
      color: "rgba(229, 109, 189, 0.24)",
    },
    {
      id: 3,
      color: "rgba(255, 197, 0,0.24)",
    },
    {
      id: 4,
      color: "rgba(27, 77, 172, 0.24)",
    },
    {
      id: 5,
      color: "rgba(210, 17, 17, 0.24)",
    },
    {
      id: 6,
      color: "rgba(112, 112, 112, 0.24)",
    },
    {
      id: 7,
      color: "rgba(173, 222, 5, 0.24)",
    },
    {
      id: 8,
      color: "rgba(163, 90, 11, 0.24)",
    },
    {
      id: 9,
      color: "rgba(255, 225, 130, 0.24)",
    },
    {
      id: 10,
      color: "rgba(108, 58, 175, 0.24)",
    },
    {
      id: 11,
      color: "rgba(132, 233, 201, 0.24)",
    },
    {
      id: 12,
      color: "rgba(248, 89, 195, 0.24)",
    },
    {
      id: 13,
      color: "rgba(0, 30, 100, 0.24)",
    },
    {
      id: 14,
      color: "rgba(255, 197, 0, 0.24)",
    },
    {
      id: 15,
      color: "rgba(18, 140, 171, 0.24)",
    },
    {
      id: 16,
      color: "rgba(246, 158, 141, 0.24)",
    },
    {
      id: 17,
      color: "rgba(10, 196, 16, 0.24)",
    },
    {
      id: 18,
      color: "rgba(156, 0, 191, 0.24)",
    },
    {
      id: 19,
      color: "rgba(149, 174, 255, 0.24)",
    },
    {
      id: 20,
      color: "rgba(33, 218, 232, 0.24)",
    },
  ];
  reportsArr: any;
  csvFileData: any = [['أسباب قضايا الطلاق المنتهية',' العدد']];
  constructor(private _http: HttpClient) {
    this.getReasonsCase();
  }

  ngOnInit(): void {}

  // fetch data from api
  async getReasonsCase() {
    this.isLoading = true;
    this.reportsCase = await this._http
      .get(`${environment.apiHost}case-reasons/report`, {
        headers: getHeadersWithToken(),
      })
      .toPromise();
    this.isLoading = false;
    this.reportsArr = JSON.parse(JSON.stringify(this.reportsCase.report));
    this.addTheme(this.reportsArr);
    this.exportData();
  }

  // add color to arr
  addTheme(reports: any) {
    for (let item in this.reportsArr) {
      this.csvFileData.push(Object.values(this.reportsArr[item]));
      this.reportsArr[item].id = +item + 1;
    }
    this.reportsArr.map((item: any) => {
      this.platte.map((color: any) => {
        if (item.id == color.id) {
          return (item.color = color.color);
        }
      });
    });
  }

  // export data csv
  exportData() {
    let bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    let blob = new Blob([bom, this.csvFileData.join("\n")], {
      type: "text/csv;charset=utf-8;",
    });
    let link = document.getElementById("Link");
    let url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "إحصائيات أسباب القضايا" + ".csv");
  }
}
