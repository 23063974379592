import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { DataService } from "../shared/services/data.service";
import { getHeadersWithToken } from "../shared/helpers/getHeadersWithToken";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  data: string;
  //currentUser = JSON.parse(localStorage.getItem('User'));

  constructor(
    private router: Router,
    private http: HttpClient,
    private _dataService: DataService
  ) {
    //
  }

  LogIn(obj) {
    return this.http.post(this._dataService._apiUrl + "admin/login", obj);
  }

  getDetails(id) {
    return this.http.get(
      this._dataService._apiUrl + 'users/me?filter={"include":["roles"]}',
      {
        headers: getHeadersWithToken(),
      }
    );
  }

  canUpdate(moduleName): boolean {
    return this.checkPermissions(moduleName, "update");
  }

  canDelete(moduleName): boolean {
    return this.checkPermissions(moduleName, "delete");
  }

  canCreate(moduleName): boolean {
    return this.checkPermissions(moduleName, "create");
  }

  //check permissions
  checkPermissions(moduleName: String, type: String): boolean {
    //if(!admin) return false;
    const adminsArray = ["admin", "superAdmin"];
    if (
      this._dataService.currentUser.hasOwnProperty("roles") &&
      this._dataService.currentUser.roles.some((roles) =>
        adminsArray.includes(roles)
      )
    )
      return true;
    const permissionType: string = (
      moduleName.toLowerCase() +
      "." +
      type.toLowerCase()
    ).toString();
    if (this._dataService.currentUser.hasOwnProperty("roles"))
      return this._dataService.currentUser.roles.includes(permissionType);
    return false;
  }

  //check if user have permission for something
  userHavePermission(permission: string) {
    if (this._dataService.currentUser.hasOwnProperty("roles"))
      return this._dataService.currentUser.roles.includes(permission);
    return false;
  }
}
