import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { HttpHeaders } from "@angular/common/http";
import { Direction } from "@angular/cdk/bidi";
import {
  RouterEvent,
  NavigationStart,
  Router,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
} from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

import { environment } from "src/environments/environment";

@Injectable()
export class DataService {
  url = "/login";
  loading = true;
  currentLanguage: string;
  currentUser = null;
  formsCols = 2;
  isMobile = false;
  direction: Direction = "rtl";
  readonly _apiUrl = environment.apiHost;

  constructor(
    private _snackBar: MatSnackBar,
    private router: Router,
    private translate: TranslateService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
    this.translate.onLangChange.subscribe((data) => {
      this.currentLanguage = data.lang;
      if (data.lang == "ar") {
        this.direction = "rtl";
      } else {
        this.direction = "ltr";
      }
    });

    breakpointObserver.observe([Breakpoints.Handset]).subscribe((result) => {
      if (result.matches) {
        this.formsCols = 1;
        this.isMobile = true;
      }
    });

    breakpointObserver.observe([Breakpoints.Tablet]).subscribe((result) => {
      if (result.matches) {
        this.formsCols = 2;
        this.isMobile = true;
      }
    });

    breakpointObserver.observe([Breakpoints.Web]).subscribe((result) => {
      if (result.matches) {
        this.formsCols = 2;
        this.isMobile = false;
      }
    });
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loading = true;
      this.url = event.url;
      this.currentUser = JSON.parse(localStorage.getItem("User"));
      //this.giveUserNewTime();
      //if(this.url != '/login') this.menuComponent.getMenuFromRoles();
    }
    if (event instanceof NavigationEnd) {
      this.loading = false;
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.loading = false;
    }
    if (event instanceof NavigationError) {
      this.loading = false;
    }
  }

  notification(msg, value = 'snackbar-color') {
    this._snackBar.open(msg, "X", {
      duration: value == 'error-snackbar' ? 0 : 1500,
      panelClass: [value],
    });
  }

  getUserToken() {
    return localStorage.getItem("token") || false;
  }

  setUserToken(token, rememberMe: string | boolean = "true") {
    let remembered =
      rememberMe == "true" || rememberMe == true ? "true" : "false";
    localStorage.setItem("rememberMe", remembered);
    if (rememberMe) localStorage.setItem("token", token);
    if (!rememberMe) sessionStorage.setItem("token", token);
  }

  getUserData() {
    /*const sessionOrLocal = localStorage.getItem('rememberMe');
    let userData;
    if (sessionOrLocal == "true") userData = localStorage.getItem('userInfo');
    if (sessionOrLocal == "false") userData = sessionStorage.getItem('userInfo');
    return userData ? JSON.parse(userData) : false;*/
    return JSON.parse(localStorage.getItem("User"));
  }

  setUserData(userObject, rememberMe: string | boolean = "true") {
    let remembered =
      rememberMe == "true" || rememberMe == true ? "true" : "false";
    localStorage.setItem("rememberMe", remembered);
    if (remembered == "true")
      localStorage.setItem("userInfo", JSON.stringify(userObject));
    if (remembered == "false")
      sessionStorage.setItem("userInfo", JSON.stringify(userObject));
  }

  redirectTo(uri: string) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = "reload";
    this.router.navigate(["/" + uri]);
  }

  forceReloadSamePage() {
    this.redirectTo(this.router.url);
  }
}
