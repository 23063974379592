import { Component } from "@angular/core";
import { AuthService } from "./core/auth.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-root",
  template: ` <app-layout></app-layout> `,
  styles: [],
})
export class AppComponent {
  title = "Admin";
  saveLanguage;

  constructor(private auth: AuthService, private translate: TranslateService) {
    //this.user = this.auth.user;
    this.saveLanguage = localStorage.getItem("defaultLanguage");
    if (this.saveLanguage) {
      translate.addLangs(["en", "ar"]);
      translate.setDefaultLang(this.saveLanguage);
      translate.use(this.saveLanguage);
    } else {
      translate.addLangs(["en", "ar"]);
      translate.setDefaultLang("ar");
      translate.use("ar");
    }
  }
}
