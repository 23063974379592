import { NgModule } from "@angular/core";
import { AuthService } from "./auth.service";
import { CoreRouting } from "./core.routing";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { registerLocaleData } from "@angular/common";
import localeEn from "@angular/common/locales/en-GB";

registerLocaleData(localeEn);

@NgModule({
  imports: [CoreRouting],
  declarations: [PageNotFoundComponent],
  providers: [AuthService],
})
export class CoreModule {}
